/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  AvatarGroup,
  Button,
  Flex,
  Grid,
  Icon,
  Menu,
  Box,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar5 from "assets/img/avatars/avatar5.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar7 from "assets/img/avatars/avatar7.png";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import IconBox from "components/Icons/IconBox";
import {
  AdobexdLogo,
  AtlassianLogo,
  JiraLogo,
  SlackLogo,
  SpotifyLogo,
} from "components/Icons/Icons";
import { HSeparator } from "components/Separator/Separator";
import React, { useReducer } from "react";
import { BsPlus } from "react-icons/bs";
import { FaCube, FaPenFancy, FaCreditCard, FaRegFileAlt, FaMapMarkedAlt } from "react-icons/fa";
import { IoDocumentsSharp, IoEllipsisVerticalSharp } from "react-icons/io5";
import AppCard from "components/pn-components/AppCard";

const reducer = (state, action) => {
  if (action.type === "SWITCH_ACTIVE") {
    if (action.payload === "sales") {
      const newState = {
        sales: true,
        crm: false,
        marketing: false,
      };
      return newState;
    } else if (action.payload === "crm") {
      const newState = {
        sales: false,
        crm: true,
        marketing: false,
      };
      return newState;
    } else if (action.payload === "marketing") {
      const newState = {
        sales: false,
        CRM: false,
        marketing: true,
      };
      return newState;
    }
  }
  return state;
};

function Projects() {
  const [state, dispatch] = useReducer(reducer, {
    sales: true,
    crm: false,
    marketing: false,
  });

  const { colorMode } = useColorMode();

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();

  const {
    isOpen: isOpen4,
    onOpen: onOpen4,
    onClose: onClose4,
  } = useDisclosure();

  const {
    isOpen: isOpen5,
    onOpen: onOpen5,
    onClose: onClose5,
  } = useDisclosure();

  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const bgIconBox = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "navy.700"
  );
  const secondaryColor = useColorModeValue("gray.500", "white");

  return (
    <Flex direction="column" mt={{ sm: "150px", md: "100px" }}>
      <Flex
        direction={{ sm: "column", md: "row" }}
        mb="24px"
        maxH="330px"
        justifyContent={{ sm: "center", md: "space-between" }}
        align="center"
        backdropFilter="blur(21px)"
        boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
        border="1.5px solid"
        borderColor={borderProfileColor}
        bg={bgProfile}
        p="24px"
        borderRadius="20px"
      >
        <Flex
          direction={{ sm: "column", lg: "row" }}
          w={{ sm: "100%", md: "50%", lg: "auto" }}
        >
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex
              align="center"
              w={{ sm: "100%", lg: "135px" }}
              bg={
                state.sales
                  ? colorMode === "dark"
                    ? "navy.900"
                    : "#fff"
                  : null
              }
              borderRadius="8px"
              justifyContent="center"
              py="10px"
              boxShadow={
                state.overview ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null
              }
              cursor="pointer"
              transition="all .5s ease"
              onClick={() =>
                dispatch({ type: "SWITCH_ACTIVE", payload: "sales" })
              }
            >
              <Icon color={textColor} as={FaCreditCard} me="6px" />
              <Text fontSize="xs" color={textColor} fontWeight="bold">
                SALES
              </Text>
            </Flex>
          </Button>
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex
              align="center"
              w={{ lg: "135px" }}
              borderRadius="8px"
              justifyContent="center"
              py="10px"
              mx={{ lg: "1rem" }}
              cursor="pointer"
              boxShadow={
                state.teams ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null
              }
              bg={
                state.crm
                  ? colorMode === "dark"
                    ? "navy.900"
                    : "#fff"
                  : null
              }
              transition="all .5s ease"
              onClick={() =>
                dispatch({ type: "SWITCH_ACTIVE", payload: "crm" })
              }
            >
              <Icon color={textColor} as={IoDocumentsSharp} me="6px" />
              <Text fontSize="xs" color={textColor} fontWeight="bold">
                CRM
              </Text>
            </Flex>
          </Button>
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex
              align="center"
              w={{ lg: "135px" }}
              borderRadius="8px"
              justifyContent="center"
              py="10px"
              cursor="pointer"
              boxShadow={
                state.projects ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null
              }
              bg={
                state.marketing
                  ? colorMode === "dark"
                    ? "navy.900"
                    : "#fff"
                  : null
              }
              transition="all .5s ease"
              onClick={() =>
                dispatch({ type: "SWITCH_ACTIVE", payload: "marketing" })
              }
            >
              <Icon color={textColor} as={FaMapMarkedAlt} me="6px" />
              <Text fontSize="xs" color={textColor} fontWeight="bold">
                MARKETING
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(2, auto)",
          lg: "repeat(3, auto)",
        }}
        templateRows={{ md: "repeat(3, auto)", lg: "repeat(2, auto)" }}
        gap="30px"
      >
        {state.sales ? (
          <Flex>
          <AppCard name={'Agency Express'} description={'Used to control the ordering of new “for sale and to let” boards and board movement.'} link={'https://signmaster.agencyexpress.net/login.cfm?destination=/index.cfm'} />
          <AppCard name={'Vouch'} description={'Used to carry out tenant referencing'} link={'https://dashboard.vouch.co.uk/wp-login.php'} />
          </Flex>
        ) : state.crm ? (
          <Flex>
          <AppCard name={'Alto CRM'} description={'The main system used for the agents day to day running of the business'} link={'https://login3.vebraalto.com/sign-in '} />
          <AppCard name={'Knowledge Base'} description={'Franchise Knowledge Base'} link={'https://knowledgebase.propertynest.co.uk/login'} />
          <AppCard name={'Movebutler'} description={'Anti money laundering checks. In respect of remaining compliant as an estate agent.'} link={'https://mymarketing.propertynest.co.uk/uStore/login.aspx?StoreID=126provides'} />
          <AppCard name={'Sharepoint'} description={'Sharepoint'} link={'https://mymarketing.propertynest.co.uk/uStore/login.aspx?StoreID=126provides'} />
          </Flex>
        ) : state.marketing ? (
          <Flex>
          <AppCard name={'Dataloft'} description={'Used to track market real time market stats and updates, also providing useful infographics and social media collateral for agent use. Provides prospecting and marketing content'} link={'https://inform.dataloft.co.uk/login '} />
          <AppCard name={'ESTAS'} description={'Used to track market real time market stats and updates, also providing useful infographics and social media collateral for agent use. Provides prospecting and marketing content'} link={'https://www.theestas.com/login'} />
          <AppCard name={'My Marketing'} description={'Besley and Copp to access the shop of stationery, branding and marketing assets. I'} link={'https://mymarketing.propertynest.co.uk/uStore/login.aspx?StoreID=126'} />
          <AppCard name={'Sprift'} description={'For prospecting new vendors and landlords. Direct mail, targeting marketing specific streets and postcodes, tracking the market in real time, and organising the distribution of letters based on set market status trigger points. E.g. price reductions, slow movers, withdrawals, new to market.'} link={'https://sprift.com/dashboard/login'} />
          </Flex>
        ) : null }
      </Grid>
    </Flex>
  );
}

export default Projects;
