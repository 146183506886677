import { HamburgerIcon } from '@chakra-ui/icons';
// chakra imports
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	Flex,
	HStack,
	Icon,
	List,
	ListItem,
	Stack,
	Text,
	useColorModeValue,
	useDisclosure
} from '@chakra-ui/react';
import IconBox from 'components/Icons/IconBox';
import {
	renderThumbDark,
	renderThumbLight,
	renderTrack,
	renderTrackRTL,
	renderView,
	renderViewRTL
} from 'components/Scrollbar/Scrollbar';
import { HSeparator } from 'components/Separator/Separator';
import { SidebarContext } from 'contexts/SidebarContext';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FaCircle } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';

import {
    CartIcon,
    DocumentIcon,
    HomeIcon,
    PersonIcon,
    StatsIcon,
  } from "components/Icons/Icons";

export default function AdminLinks(props){
    const key="Home"
    let variantChange = '0.2s linear';
    const activeRoute = (routeName) => {
        console.log(location.pathname)
		return location.hash.includes(routeName);
	};
    let activeBg = 'blue.500';
	let inactiveBg = useColorModeValue('transparent', 'navy.700');
	let activeColor = useColorModeValue('gray.700', 'white');
	let inactiveColor = useColorModeValue('gray.400', 'gray.400');
	let activeAccordionBg = useColorModeValue('white', 'navy.700');
	let sidebarActiveShadow = useColorModeValue('0px 7px 11px rgba(0, 0, 0, 0.04)', 'none');
	let activeColorIcon = 'white';
	let inactiveColorIcon = 'blue.500';
	let sidebarBackgroundColor = useColorModeValue('white', 'navy.900');
    return (
        <Box key={key}>
        <Text
            fontSize={'md'}
            color={activeColor}
            fontWeight='bold'
            mx='auto'
            ps={{
                sm: '10px',
                xl: '16px'
            }}
            pt='18px'
            pb='12px'>
            Admin
        </Text>
        <NavLink to={'/admin/usermanager'}>
        <Box>
        {activeRoute('/userrmanager') ? (
                <HStack spacing='14px' py='15px' px='15px'>
                <IconBox bg='blue.500' color='white' h='30px' w='30px' transition={variantChange}>
                    <HomeIcon />
                </IconBox>
                <Text
                    color={activeRoute('/admin/usermanager') ? activeColor : inactiveColor}
                    fontWeight={activeRoute('/admin/usermanager') ? 'bold' : 'normal'}
                    fontSize='sm'>
                    User Manager
                </Text>
            </HStack>
            ): (
                <HStack spacing='14px' py='15px' px='15px'>
                    <IconBox bg={inactiveBg} color={inactiveColorIcon} h='30px' w='30px' transition={variantChange}>
                        <HomeIcon />
                    </IconBox>
                    <Text
                        color={activeRoute('/admin/usermanager') ? activeColor : inactiveColor}
                        fontWeight={activeRoute('/admin/usermanager') ? 'bold' : 'normal'}
                        fontSize='sm'>
                        User Manager
                    </Text>
                </HStack>
            )
        }    
            </Box>
        </NavLink>
        <NavLink to={'/admin/feedmanager'}>
        <Box>
            {activeRoute('/feedmanager') ? (
                <HStack spacing='14px' py='15px' px='15px'>
                <IconBox bg='blue.500' color='white' h='30px' w='30px' transition={variantChange}>
                    <HomeIcon />
                </IconBox>
                <Text
                    color={activeRoute('/feedmanager') ? activeColor : inactiveColor}
                    fontWeight={activeRoute('/feedmanager') ? 'bold' : 'normal'}
                    fontSize='sm'>
                    Feed Manager
                </Text>
            </HStack>
            ): (
                <HStack spacing='14px' py='15px' px='15px'>
                    <IconBox bg={inactiveBg} color={inactiveColorIcon} h='30px' w='30px' transition={variantChange}>
                        <HomeIcon />
                    </IconBox>
                    <Text
                        color={activeRoute('/feedmanager') ? activeColor : inactiveColor}
                        fontWeight={activeRoute('/feedmanager') ? 'bold' : 'normal'}
                        fontSize='sm'>
                        Feed Manager
                    </Text>
                </HStack>
            )
        }    
            </Box>
        </NavLink>
    </Box>
    )
}