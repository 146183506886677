import axios from "axios";
import instance from "hooks/useInterceptor"

//const BASE_URL = "http://localhost:8000/webapi/"
const BASE_URL = "https://api.propertynest.co.uk/webapi/"

class ApiService {
    register(firstName, lastName, email, password, securityQuestion, securityQuestionAnswer, registerKey){
        return axios.post(BASE_URL+'register', {
            firstname: firstName,
            lastname: lastName,
            email: email,
            password: password,
            security_question: securityQuestion,
            security_question_answer: securityQuestionAnswer,
            register_key: registerKey
        }).then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    getPermissions(){
        return instance.get('/user/permissions').then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    getusers(){
        return instance.get('/allusers').then(response => {
            return response.data
        }).catch(err => {
            return err.response
        })
    }
    getUserProfileAdmin(userid){
        return instance.get('/userprofile/'+userid).then(response => {
            return response.data
        }).catch(err =>{
            return err.response.data
        })
    }
    getAppsCategories(){
        return instance.get('/apps').then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    getAppPermissions(userid){
        return instance.post('/apps/permissions', {
            "userid":userid
        }).then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    setUserPermission(category_id, user_id, status){
        return instance.post('/user/app/permissions', {
            'userid':user_id,
            'category_id':category_id,
            'status': status
        }).then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    adminResetAccountPassword(userid){
        return instance.post('/user/resetpassword', {
            'userid':userid
        }).then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    inviteuser(emailAddress){
        return instance.post('/registrationkey', {
            "email_address":emailAddress
        }).then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    submitBasicInfo(account_holder, business_name, email_address, company_registration_number, company_director1, company_director2, company_vat_number, business_line1, business_line2, business_city, business_postcode){
        return instance.post('/onboarding/1', {
            "onboarding_step": 1,
            "account_holder": account_holder,
            "business_name": business_name,
            "email_address": email_address,
            "company_registration_number": company_registration_number,
            "company_director1": company_director1,
            "company_director2": company_director2,
            "company_vat_number": company_vat_number,
            "business_line1": business_line1,
            "business_line2": business_line2,
            "business_city": business_city,
            "business_postcode": business_postcode
        }).then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    getOnboardingStatus(){
        return instance.get('/onboarding').then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    getOnboardingPerms(){
        return instance.get('/onboarding/permissions').then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    getBranches(){
        return instance.get('/branches').then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    getBranchInfo(){
        return instance.get('/branches/info').then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    getPropertyInfoSmall(id){
        return instance.get('/branch/'+id+'/properties/small').then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    getBranchById(branch_id){
        return instance.get('/branch/'+branch_id).then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    updateBranchHierarchy(branch_id, hierarchy){
        return instance.post('/branch/'+branch_id+'/hierarchy', hierarchy).then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    getFranchises(){
        return instance.get('/franchises').then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    createNewBranch(branch_data){
        return instance.post('/branch/new', branch_data).then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    uploadBranchImage(branch_image, branch_id){
        const formData = new FormData();
        formData.append("file", branch_image)
        return instance.post('/branch/'+branch_id+'/images', formData).then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    testBranchFeed(feed_type, feed_url, branch_id){
        return instance.post('/branch/'+branch_id+'/feeds/test', {
            'branch_id': branch_id,
            'feed_type': feed_type,
            'feed_url': feed_url
        }).then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    addBranchFeed(feed_type, feed_url, branch_id){
        return instance.post('/branch/'+branch_id+'/feeds', {
            'branch_id': branch_id,
            'feed_type': feed_type,
            'feed_url': feed_url
        }).then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    getBranchFeeds(branch_id){
        return instance.get('/branch/'+branch_id+'/feeds').then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    updateBranchFeed(branch_id, feed_id, feed_enabled, feed_url){
        return instance.post('/branch/feeds/'+feed_id, {
            'branch_id': branch_id,
            'enabled': feed_enabled,
            'url': feed_url
        }).then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
    getSingleProperty(property_id){
        return instance.get('/property/'+property_id).then(response => {
            return response.data
        }).catch(err => {
            return err.response.data
        })
    }
}

export default new ApiService