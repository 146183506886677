/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  Select,
  Spinner,
  Center
} from '@chakra-ui/react';
import avatar4 from 'assets/img/avatars/avatar4.png';
// Custom components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import IconBox from 'components/Icons/IconBox';
import { RocketIcon } from 'components/Icons/Icons';
import React, { useEffect, useRef, useState } from 'react';
import { AiFillSetting } from 'react-icons/ai';
import { BsCircleFill } from 'react-icons/bs';
import { FaCube } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import ApiService from 'hooks/useApi'
import { GiConsoleController } from 'react-icons/gi';
import { check } from 'prettier';

function Wizard() {
  const textColor = useColorModeValue('gray.700', 'white');
  const bgPrevButton = useColorModeValue('gray.100', 'gray.100');
  const iconColor = useColorModeValue('gray.300', 'gray.700');
  const [activeBullets, setActiveBullets] = useState({
    about: true,
    account: false,
    address: false,
  });

  const [checkboxes, setCheckboxes] = useState({
    residential_sales: false,
    residential_lettings: false,
  });

  const [isLoaded, setisLoaded] = useState();
  const [franchises, setFranchises] = useState();

  const [inputFranchise, setInputFranchise] = useState();
  const [inputBranchName, setInputBranchName] = useState();
  const [inputTradingNumber, setInputTradingNumber] = useState();
  const [inputTradingStreet, setInputTradingStreet] = useState();
  const [inputTradingCity, setInputTradingCity] = useState();
  const [inputTradingCounty, setInputTradingCounty] = useState();
  const [inputTradingPostcode, setInputTradingPostcode] = useState();
  const [inputTradingPhoneNumber, setInputTradingPhoneNumber] = useState();
  const [inputTradingEmailAddress, setInputTradingEmailAddress] = useState();
  const [servicesResidentialLettings, setServicesResidentialLettings] = useState(false);
  const [servicesResidentialSales, setServicesResidentialSales] = useState(false);
  const [inputCompanyName, setInputCompanyName] = useState();
  const [inputCompanyAddressName, setInputCompanyAddressName] = useState();
  const [inputCompanyAddressLine1, setInputCompanyAddressLine1] = useState();
  const [inputCompanyAddressLine2, setInputCompanyAddressLine2] = useState();
  const [inputCompanyCity, setInputCompanyCity] = useState()
  const [inputCompanyCounty, setInputCompanyCounty] = useState()
  const [inputCompanyPostcode, setInputCompanyPostcode] = useState()
  const [inputCompanyRegistrationNumber, setInputCompanyRegistrationNumber] = useState()
  const [inputCompanyVatNumber, setInputCompanyVatNumber] = useState()
  const [inputCompanyPhone, setInputCompanyPhone] = useState()
  const [inputCompanyCountry, setInputCompanyCountry] = useState()

  const [submitBranchRegistration, setSubmitBranchRegistration] = useState(false)

  const aboutTab = useRef();
  const accountTab = useRef();
  const addressTab = useRef();

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  function handleChange(e){
    console.log(e.target.name)
    if(e.target.name == 'branchName'){
      setInputBranchName(e.target.value)
    }else if(e.target.name == 'franchise'){
      setInputFranchise(e.target.value)
    }else if(e.target.name == 'trading_address_number'){
      setInputTradingNumber(e.target.value)
    } else if(e.target.name == 'trading_address_street'){
      setInputTradingStreet(e.target.value)
    } else if(e.target.name == 'trading_address_city'){
      setInputTradingCity(e.target.value)
    } else if(e.target.name == 'trading_address_county'){
      setInputTradingCounty(e.target.value)
    } else if(e.target.name == 'trading_address_postcode'){
      setInputTradingPostcode(e.target.value)
    } else if(e.target.name == 'trading_address_phone'){
      setInputTradingPhoneNumber(e.target.value)
    } else if(e.target.name == 'trading_address_email'){
      setInputTradingEmailAddress(e.target.value)
    } else if(e.target.name == 'registered_company_name'){
      setInputCompanyName(e.target.value)
    } else if(e.target.name == 'registered_company_address_name'){
      setInputCompanyAddressName(e.target.value)
    } else if(e.target.name == 'company_address_line1'){
      setInputCompanyAddressLine1(e.target.value)
    } else if(e.target.name == 'company_address_line2'){
      setInputCompanyAddressLine2(e.target.value)
    } else if(e.target.name == 'company_address_city'){
      setInputCompanyCity(e.target.value)
    } else if(e.target.name == 'company_address_county'){
      setInputCompanyCounty(e.target.value)
    } else if(e.target.name == 'company_address_postcode'){
      setInputCompanyPostcode(e.target.value)
    } else if(e.target.name == 'company_registration_number'){
      setInputCompanyRegistrationNumber(e.target.value)
    } else if(e.target.name == 'company_vat_number'){
      setInputCompanyVatNumber(e.target.value)
    } else if(e.target.name == 'company_phone_number'){
      setInputCompanyPhone(e.target.value)
    } else if(e.target.name == 'company_country'){
      setInputCompanyCountry(e.target.value)
    }
  }
  async function handleSubmit(e){
    e.preventDefault
    setSubmitBranchRegistration(true)
    await delay(1500)
    ApiService.createNewBranch({
      'branch_name':inputBranchName,
      'franchise_id':inputFranchise,
      'trading_address_number':inputTradingNumber,
      'trading_address_street':inputTradingStreet,
      'trading_address_city':inputTradingCity,
      'trading_address_county':inputTradingCounty,
      'trading_address_postcode':inputTradingPostcode,
      'trading_phone_number':inputTradingPhoneNumber,
      'trading_email_address':inputTradingEmailAddress,
      'registered_company_name':inputCompanyName,
      'registered_company_address_name':inputCompanyAddressName,
      'company_address_line1':inputCompanyAddressLine1,
      'company_address_line2':inputCompanyAddressLine2,
      'company_address_city':inputCompanyCity,
      'company_address_county':inputCompanyCounty,
      'company_address_postcode':inputCompanyPostcode,
      'company_registration_number':inputCompanyRegistrationNumber,
      'company_vat_number':inputCompanyVatNumber,
      'company_phone_number':inputCompanyPhone,
      'company_country':inputCompanyCountry,
      'residential_sales':checkboxes.residential_sales,
      'residential_lettings':checkboxes.residential_lettings,
    }).then(data => {
      console.log(data)
      window.location = "#/admin/editbranch/"+data.data
    })
    
  }

  useEffect(()=>{
    ApiService.getFranchises().then(data => {
      setFranchises(data)
      setisLoaded(true)
    })
  },[])

  return (
    <Flex
      direction='column'
      minH='100vh'
      align='center'
      pt={{ sm: '125px', lg: '75px' }}
    >
      <Flex
        direction='column'
        textAlign='center'
        mb={{ sm: '25px', md: '45px' }}
      >
        <Text
          color={textColor}
          fontSize={{ sm: '2xl', md: '3xl', lg: '4xl' }}
          fontWeight='bold'
          mb='8px'
        >
          Create a new branch
        </Text>
      </Flex>
      <Tabs variant='unstyled' mt='24px' display='flex' flexDirection='column'>
        <TabList
          display='flex'
          align='center'
          alignSelf='center'
          justifySelf='center'
        >
          <Tab
            ref={aboutTab}
            _focus={{}}
            w={{ sm: '120px', md: '250px', lg: '300px' }}
            onClick={() =>
              setActiveBullets({
                about: true,
                account: false,
                address: false,
              })
            }
          >
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'
              _before={{
                content: "''",
                width: { sm: '120px', md: '250px', lg: '300px' },
                height: '3px',
                bg: activeBullets.account ? 'white' : 'blue.300',
                left: { sm: '12px', md: '30px' },
                top: {
                  sm: activeBullets.about ? '6px' : '4px',
                  md: null,
                },
                position:'absolute',
                bottom: activeBullets.about ? '40px' : '38px',

                transition: 'all .3s ease',
              }}
            >
              <Icon
                zIndex='1'
                as={BsCircleFill}
                color={activeBullets.about ? 'white' : 'blue.300'}
                w={activeBullets.about ? '16px' : '12px'}
                h={activeBullets.about ? '16px' : '12px'}
                mb='8px'
              />
              <Text
                color={activeBullets.about ? 'white' : 'gray.300'}
                fontWeight={activeBullets.about ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                About
              </Text>
            </Flex>
          </Tab>
          <Tab
            ref={accountTab}
            _focus={{}}
            w={{ sm: '120px', md: '250px', lg: '300px' }}
            onClick={() =>
              setActiveBullets({
                about: true,
                account: true,
                address: false,
              })
            }
          >
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'
              _before={{
                content: "''",
                width: { sm: '120px', md: '250px', lg: '300px' },
                height: '3px',
                bg: activeBullets.address ? 'white' : 'blue.300',
                left: { sm: '12px', md: '32px' },
                top: {
                  sm: activeBullets.account ? '6px' : '4px',
                  md: null,
                },
                position: 'absolute',
                bottom: activeBullets.account ? '40px' : '38px',

                transition: 'all .3s ease',
              }}
            >
              <Icon
                zIndex='1'
                as={BsCircleFill}
                color={activeBullets.account ? 'white' : 'blue.300'}
                w={activeBullets.account ? '16px' : '12px'}
                h={activeBullets.account ? '16px' : '12px'}
                mb='8px'
              />
              <Text
                color={activeBullets.account ? 'white' : 'gray.300'}
                fontWeight={activeBullets.account ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Account
              </Text>
            </Flex>
          </Tab>
          <Tab
            ref={addressTab}
            _focus={{}}
            w={{ sm: '120px', md: '250px', lg: '300px' }}
            onClick={() =>
              setActiveBullets({
                about: true,
                account: true,
                address: true,
              })
            }
          >
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'
            >
              <Icon
                zIndex='1'
                as={BsCircleFill}
                color={activeBullets.address ? 'white' : 'blue.300'}
                w={activeBullets.address ? '16px' : '12px'}
                h={activeBullets.address ? '16px' : '12px'}
                mb='8px'
              />
              <Text
                color={activeBullets.address ? 'white' : 'gray.300'}
                fontWeight={activeBullets.address ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Address
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels mt='24px' maxW={{ md: '90%', lg: '100%' }} mx='auto'>
          <TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} mx='auto'>
          {isLoaded ? (
              <Card>
              <CardHeader mb='40px'>
                <Flex
                  direction='column'
                  align='center'
                  justify='center'
                  textAlign='center'
                  w='80%'
                  mx='auto'
                >
                  <Text
                    color={textColor}
                    fontSize='lg'
                    fontWeight='bold'
                    mb='4px'
                  >
                    Let's start with the basic information
                  </Text>
                  <Text color='gray.400' fontWeight='normal' fontSize='sm'>
                    Let us know about your new branch
                  </Text>
                </Flex>
              </CardHeader>
              <CardBody>
                <Flex direction='column' w='100%'>
                  <Flex
                    direction={{ sm: 'column', md: 'row' }}
                    w='100%'
                    mb='24px'
                  >
                    <Box
                      position='relative'
                      minW={{ sm: '110px', xl: '150px' }}
                      h={{ sm: '110px', xl: '150px' }}
                      mx={{ sm: 'auto', md: '40px', xl: '85px' }}
                      mb={{ sm: '25px' }}
                    >
                      <Avatar
                        src="https://my-propertynest-cdn.ams3.digitaloceanspaces.com/branch/branch_placeholder.png"
                        w='100%'
                        h='100%'
                        borderRadius='12px'
                      />
                      <IconBox
                        bg='#fff'
                        h='35px'
                        w='35px'
                        boxShadow='0px 3.5px 5.5px rgba(0, 0, 0, 0.06)'
                        position='absolute'
                        right='-10px'
                        bottom='-10px'
                        cursor='pointer'
                      >
                        <Icon as={MdModeEdit} w='15px' h='15px' color='#333' />
                      </IconBox>
                    </Box>
                    <Stack direction='column' spacing='20px' w='100%'>
                    <FormControl>
                        <FormLabel
                          color={textColor}
                          fontSize='xs'
                          fontWeight='bold'
                        >
                          Franchise
                        </FormLabel>
                        <Select
                          variant='main'
                          fontSize='xs'
                          color='gray.400'
                          name="franchise"
                          onChange={handleChange}
                        >
                        <option value='0'></option>
                        {franchises.map((franchise) =>(
                          <option value={franchise.id}>{franchise.name}</option>
                        ))}
                        </Select>
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontSize='xs'
                          fontWeight='bold'
                        >
                          Branch Name
                        </FormLabel>
                        <Input
                          variant='main'
                          placeholder='eg. East Estates'
                          fontSize='xs'
                          name="branchName"
                          onChange={handleChange}
                        />
                      </FormControl>
                      <Stack direction='column' spacing='20px' w='100%'>
                      <Text
                          color={textColor}
                          fontSize='lg'
                          fontWeight='bold'
                          mb='4px'
                        >
                        Trading Address
                        </Text>
                       <Stack direction={{ sm: 'column', md: 'row' }} spacing='30px'>
                         <FormControl>
                           <FormLabel fontSize='xs' fontWeight='bold' mb='10px'>
                              Number
                           </FormLabel>
                             <Input
                             variant='main'
                             placeholder='eg. 12a'
                             id='trading_address_number'
                             name='trading_address_number'
                             onChange={handleChange}
                             fontSize='xs'
                           />
                         </FormControl>
                         <FormControl>
                           <FormLabel fontSize='xs' fontWeight='bold' mb='10px'>
                             Street
                           </FormLabel>
                             <Input variant='main'
                             placeholder='eg. Estate Street'
                             fontSize='xs'
                             id='street'
                             name='trading_address_street'
                             onChange={handleChange}
                             />
                         </FormControl>
                       </Stack>
                       <Stack direction={{ sm: 'column', lg: 'row' }} spacing='30px'>
                         <FormControl>
                         <FormLabel fontSize='xs' fontWeight='bold' mb='10px'>
                             Town/City
                           </FormLabel>
                             <Input variant='main'
                             placeholder='eg. Leeds'
                             fontSize='xs'
                             id='town'
                             name='trading_address_city'
                             onChange={handleChange}
                             />
                         </FormControl>
                         <FormControl>
                         <FormLabel fontSize='xs' fontWeight='bold' mb='10px'>
                             County
                           </FormLabel>
                             <Input variant='main' 
                             placeholder='eg. West Yorkshire' 
                             contentEditable='true' 
                             fontSize='xs' 
                             id='county'
                             name='trading_address_county'
                             onChange={handleChange}
                             />
                         </FormControl>
                         <FormControl>
                         <FormLabel fontSize='xs' fontWeight='bold' mb='10px'>
                             Postcode
                           </FormLabel>
                             <Input variant='main'
                             placeholder='eg. Postcode'
                             fontSize='xs'
                             id='county'
                             name='trading_address_postcode'
                             onChange={handleChange}
                             />
                         </FormControl>
                       </Stack>
                     </Stack>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontSize='xs'
                          fontWeight='bold'
                        >
                          Phone Number
                        </FormLabel>
                        <Input
                          variant='main'
                          placeholder='eg. 0800 223344'
                          fontSize='xs'
                          name='trading_address_phone'
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontSize='xs'
                          fontWeight='bold'
                        >
                          Email Address
                        </FormLabel>
                        <Input
                          variant='main'
                          placeholder='eg. info@estateagent.co.uk'
                          fontSize='xs'
                          name='trading_address_email'
                          onChange={handleChange}
                        />
                      </FormControl>
                    </Stack>
                  </Flex>
                  <Button
                    variant='dark'
                    alignSelf='flex-end'
                    mt='24px'
                    w={{ sm: '75px', lg: '100px' }}
                    h='35px'
                    onClick={() => accountTab.current.click()}
                  >
                    <Text fontSize='xs' color='#fff' fontWeight='bold'>
                      NEXT
                    </Text>
                  </Button>
                </Flex>
              </CardBody>
            </Card>
            ):null}
            
          </TabPanel>
          <TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} mx='auto'>
            <Card>
              <CardHeader mb='40px'>
                <Flex
                  direction='column'
                  align='center'
                  justify='center'
                  textAlign='center'
                  w='80%'
                  mx='auto'
                >
                  <Text
                    color={textColor}
                    fontSize='lg'
                    fontWeight='bold'
                    mb='4px'
                  >
                    What types of services does this branch provide?
                  </Text>
                </Flex>
              </CardHeader>
              <CardBody>
                <Flex direction='column' w='100%'>
                  <Stack
                    direction={{ sm: 'column', md: 'row' }}
                    spacing={{ sm: '20px', lg: '35px' }}
                    alignSelf='center'
                    justifySelf='center'
                    mb='24px'
                  >
                    <Flex direction='column' align='center'>
                      <FormLabel w='150px' h='150px' cursor='pointer' mb='16px'>
                        <Flex
                          w='100%'
                          h='100%'
                          borderRadius='8px'
                          justify='center'
                          transition='.5s all ease'
                          border={
                            checkboxes.residential_sales ? 'none' : '1px solid lightgray'
                          }
                          align='center'
                          bg={checkboxes.residential_sales ? 'blue.500' : '#fff'}
                          _hover={{ opacity: '0.8' }}
                        >
                          <Checkbox
                            onChange={() =>
                              setCheckboxes((prevCheckboxes) => {
                                return {
                                  ...prevCheckboxes,
                                  residential_sales: !prevCheckboxes.residential_sales,
                                };
                              })
                            }
                            display='none'
                          />
                          <Icon
                            as={AiFillSetting}
                            w='54px'
                            h='54px'
                            color={checkboxes.residential_sales ? '#fff' : iconColor}
                          />
                        </Flex>
                      </FormLabel>
                      <Text color={textColor} fontWeight='bold' fontSize='md'>
                        Residential Sales
                      </Text>
                    </Flex>
                    <Flex direction='column' align='center'>
                      <FormLabel w='150px' h='150px' cursor='pointer' mb='16px'>
                        <Flex
                          w='100%'
                          h='100%'
                          borderRadius='8px'
                          justify='center'
                          transition='.5s all ease'
                          border={
                            checkboxes.residential_lettings ? 'none' : '1px solid lightgray'
                          }
                          align='center'
                          bg={checkboxes.residential_lettings ? 'blue.500' : '#fff'}
                          _hover={{ opacity: '0.8' }}
                        >
                          <Checkbox
                            onChange={() =>
                              setCheckboxes((prevCheckboxes) => {
                                return {
                                  ...prevCheckboxes,
                                  residential_lettings: !prevCheckboxes.residential_lettings,
                                };
                              })
                            }
                            display='none'
                          />
                          <Icon
                            as={FaCube}
                            w='54px'
                            h='54px'
                            color={checkboxes.residential_lettings ? '#fff' : iconColor}
                          />
                        </Flex>
                      </FormLabel>
                      <Text color={textColor} fontWeight='bold' fontSize='md'>
                        Residential Lettings
                      </Text>
                    </Flex>
                  </Stack>

                  <Flex justify='space-between'>
                    <Button
                      variant='no-effects'
                      bg={bgPrevButton}
                      alignSelf='flex-end'
                      mt='24px'
                      w={{ sm: '75px', lg: '100px' }}
                      h='35px'
                      onClick={() => aboutTab.current.click()}
                    >
                      <Text fontSize='xs' color='gray.700' fontWeight='bold'>
                        PREV
                      </Text>
                    </Button>
                    <Button
                      variant='dark'
                      alignSelf='flex-end'
                      mt='24px'
                      w={{ sm: '75px', lg: '100px' }}
                      h='35px'
                      onClick={() => addressTab.current.click()}
                    >
                      <Text fontSize='xs' color='#fff' fontWeight='bold'>
                        NEXT
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
          <TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} mx='auto'>
            <Card>
              <CardHeader mb='40px'>
                <Flex
                  direction='column'
                  align='center'
                  justify='center'
                  textAlign='center'
                  w='80%'
                  mx='auto'
                >
                  <Text
                    color={textColor}
                    fontSize='lg'
                    fontWeight='bold'
                    mb='4px'
                  >
                    Company Information
                  </Text>
                  <Text color='gray.400' fontWeight='normal' fontSize='sm'>
                    Please provide company information
                  </Text>
                </Flex>
              </CardHeader>
              <CardBody>
                <Flex direction='column' w='100%'>
                  {submitBranchRegistration == false ? (
                    <Stack direction='column' spacing='20px'>
                    <FormControl>
                        <FormLabel
                          color={textColor}
                          fontWeight='bold'
                          fontSize='xs'
                        >
                          Registered Company Name
                        </FormLabel>
                        <Input
                          variant='main'
                          placeholder='eg. East Estates LTD'
                          fontSize='xs'
                          name='registered_company_name'
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontWeight='bold'
                          fontSize='xs'
                        >
                          Company Address Name/Number
                        </FormLabel>
                        <Input
                          variant='main'
                          placeholder='eg. Unit 12b'
                          fontSize='xs'
                          name='registered_company_address_name'
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontWeight='bold'
                          fontSize='xs'
                        >
                          Company Address Line 1
                        </FormLabel>
                        <Input
                          variant='main'
                          placeholder='eg. Walker Street'
                          fontSize='xs'
                          name='company_address_line1'
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontWeight='bold'
                          fontSize='xs'
                        >
                          Company Address Line 2
                        </FormLabel>
                        <Input
                          variant='main'
                          placeholder='eg. West Building'
                          fontSize='xs'
                          name='company_address_line2'
                          onChange={handleChange}
                        />
                      </FormControl>
                      <Grid
                        templateColumns={{ sm: '1fr 1fr', lg: '2fr 1fr 1fr' }}
                        gap='30px'
                      >
                        <FormControl gridColumn={{ sm: '1 / 3', lg: 'auto' }}>
                          <FormLabel
                            color={textColor}
                            fontWeight='bold'
                            fontSize='xs'
                          >
                            City
                          </FormLabel>
                          <Input
                            variant='main'
                            placeholder='eg. Manchester'
                            fontSize='xs'
                            name='company_address_city'
                            onChange={handleChange}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel
                            color={textColor}
                            fontWeight='bold'
                            fontSize='xs'
                          >
                            County
                          </FormLabel>
                          <Input variant='main'
                          placeholder='West Yorkshire' 
                          fontSize='xs' 
                          name='company_address_county'
                          onChange={handleChange}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel
                            color={textColor}
                            fontWeight='bold'
                            fontSize='xs'
                          >
                            Postcode
                          </FormLabel>
                          <Input
                            variant='main'
                            placeholder='eg. LS15 8BN'
                            fontSize='xs'
                            name='company_address_postcode'
                            onChange={handleChange}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        templateColumns={{ sm: '1fr 1fr', lg: '2fr 1fr 1fr' }}
                        gap='30px'
                      >
                        <FormControl gridColumn={{ sm: '1 / 3', lg: 'auto' }}>
                          <FormLabel
                            color={textColor}
                            fontWeight='bold'
                            fontSize='xs'
                          >
                            Company Registration Number
                          </FormLabel>
                          <Input
                            variant='main'
                            placeholder='eg. SC06488522'
                            fontSize='xs'
                            name='company_registration_number'
                            onChange={handleChange}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel
                            color={textColor}
                            fontWeight='bold'
                            fontSize='xs'
                          >
                            VAT Number
                          </FormLabel>
                          <Input variant='main' 
                          placeholder='GB123456789' 
                          fontSize='xs' 
                          name='company_vat_number'
                          onChange={handleChange}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel
                            color={textColor}
                            fontWeight='bold'
                            fontSize='xs'
                          >
                            Phone Number
                          </FormLabel>
                          <Input
                            variant='main'
                            placeholder='eg. 0800 1234567'
                            fontSize='xs'
                            name='company_phone_number'
                            onChange={handleChange}
                          />
                        </FormControl>
                      </Grid>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontWeight='bold'
                          fontSize='xs'
                        >
                          Company Country
                        </FormLabel>
                        <Input
                          variant='main'
                          placeholder='eg. United Kingdom'
                          fontSize='xs'
                          name='company_country'
                          onChange={handleChange}
                        />
                      </FormControl>
                    </Stack>
                  ):
                  <Stack direction='column' spacing='20px'>
                    <Center><Spinner 
                    size='xl'
                  />
                  </Center>
                    <Center><Text>Now creating your new Branch</Text></Center>
                  </Stack>
                  }
                  <Flex justify='space-between'>
                    {submitBranchRegistration == false ? (
                      <Button
                      variant='no-effects'
                      bg={bgPrevButton}
                      alignSelf='flex-end'
                      mt='24px'
                      w={{ sm: '75px', lg: '100px' }}
                      h='35px'
                      onClick={() => accountTab.current.click()}
                    >
                      <Text fontSize='xs' color='gray.700' fontWeight='bold'>
                        PREV
                      </Text>
                    </Button>
                    ) :(
                      <Button
                      variant='no-effects'
                      bg={bgPrevButton}
                      alignSelf='flex-end'
                      mt='24px'
                      w={{ sm: '75px', lg: '100px' }}
                      h='35px'
                      onClick={() => accountTab.current.click()}
                      disabled
                    >
                      <Text fontSize='xs' color='gray.700' fontWeight='bold'>
                        PREV
                      </Text>
                    </Button>
                    )}
                    {submitBranchRegistration == false ? (
                      <Button
                      variant='dark'
                      alignSelf='flex-end'
                      mt='24px'
                      w={{ sm: '75px', lg: '100px' }}
                      h='35px'
                      onClick={handleSubmit}
                    >
                      <Text fontSize='xs' color='#fff' fontWeight='bold'>
                        SUBMIT
                      </Text>
                    </Button>
                    ):(
                      <Button
                      variant='dark'
                      alignSelf='flex-end'
                      mt='24px'
                      w={{ sm: '75px', lg: '100px' }}
                      h='35px'
                      onClick={handleSubmit}
                      disabled
                    >
                      <Text fontSize='xs' color='#fff' fontWeight='bold'>
                        SUBMIT
                      </Text>
                    </Button>
                    )}
                    
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default Wizard;
