/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/provider";
import theme from "theme/theme.js";

import AuthCover from "layouts/AuthCover.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import LandingLayout from "layouts/Landing";
import SignInCover from "views/Authentication/SignIn/SignInCover";
import SignUpCover from 'views/Authentication/SignUp/SignUpCover'

ReactDOM.render(
  <ChakraProvider theme={theme} resetCss={false} position="relative">
    <HashRouter>
      <Switch>
        <Route path={`/auth`} component={SignInCover} />
        <Route path={'/register/:registerKey'} component={SignUpCover} />
        <Route path={`/admin`} component={AdminLayout} />
        <Redirect from={`/`} to="/auth/" />
      </Switch>
    </HashRouter>
  </ChakraProvider>,
  document.getElementById("root")
);
