import jwt from 'jwt-decode'


class JwtService {
    getUserData(){
        const localToken = localStorage.getItem('user')
        const jwtData = jwt(JSON.parse(localToken).access_token)
        const userData = JSON.parse(jwtData.sub)
        return userData
    }
    getJwt(){
        const userData = localStorage.getItem('user')
        return JSON.parse(userData).access_token
    }
    getRefreshToken(){
        const userData = localStorage.getItem('user')
        return JSON.parse(userData).refresh_token
    }
    updateLocalData(data){
        localStorage.setItem('user', JSON.stringify(data))
    }
}

export default new JwtService();