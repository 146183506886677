import {
    Flex, 
    Text,
    Avatar,
    AvatarGroup,
    Box,
    Image,
    Button,
    useColorMode,
    useColorModeValue
} from "@chakra-ui/react";

import ImageArchitect1 from "assets/img/ImageArchitect1.png";
import ImageArchitect2 from "assets/img/ImageArchitect2.png";
import ImageArchitect3 from "assets/img/ImageArchitect3.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar5 from "assets/img/avatars/avatar5.png";
import avatar6 from "assets/img/avatars/avatar6.png";

function BranchTile(props){
    const textColor = useColorModeValue("gray.700", "white");
    const iconColor = useColorModeValue("blue.500", "white");
    const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
    const borderProfileColor = useColorModeValue("white", "transparent");
    function handleViewBranchClick(e){
        e.preventDefault
        window.location = "#/admin/editbranch/"+e.target.value
    }
    return (
        <Flex direction="column">
              <Box mb="20px" position="relative" borderRadius="15px">
                <Image src={props.branch_image} borderRadius="15px" />
                <Box
                  w="100%"
                  h="100%"
                  position="absolute"
                  top="0"
                  borderRadius="15px"
                  bg="linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, rgba(21, 25, 40, 0.88) 100%)"
                ></Box>
              </Box>
              <Flex direction="column">
                <Text
                  fontSize="xl"
                  color={textColor}
                  fontWeight="bold"
                  mb="10px"
                >
                  {props.name}
                </Text>
                <Text fontSize="md" color="gray.400" fontWeight="400" mb="20px">
                    <Text>{props.street}</Text>
                    <Text>{props.town}</Text>
                    <Text>{props.county}</Text>
                    <Text>{props.postcode}</Text>
                </Text>
                <Flex justifyContent="space-between">
                  <Button variant="dark" minW="110px" h="36px" value={props.id} onClick={handleViewBranchClick}>
                    View Branch
                  </Button>
                </Flex>
              </Flex>
            </Flex>
    )}

export default BranchTile
