/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect, useReducer, useState, useRef } from "react";

import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Link,
  Spinner,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
  VStack,
  StackDivider,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  List,
  ListItem,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
// Assets
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar5 from "assets/img/avatars/avatar5.png";
import avatar6 from "assets/img/avatars/avatar6.png";
import ImageArchitect1 from "assets/img/ImageArchitect1.png";
import ImageArchitect2 from "assets/img/ImageArchitect2.png";
import ImageArchitect3 from "assets/img/ImageArchitect3.png";
import Wizard from "views/Applications/Wizard"
import {
  FaCube,
  FaFacebook,
  FaInstagram,
  FaPenFancy,
  FaPlus,
  FaTwitter,
} from "react-icons/fa";
import {
  AdobexdLogo,
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  JiraLogo,
  RocketIcon,
  SettingsIcon,
  SlackLogo,
  SpotifyLogo,
  WalletIcon,
} from 'components/Icons/Icons.js';
import { UnlockIcon } from '@chakra-ui/icons'
import { IoCloudyNight, IoDocumentsSharp } from "react-icons/io5";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { HSeparator } from "components/Separator/Separator";
import ApiService from 'hooks/useApi';
import BranchTile from "components/Propertynest/BranchTile"
import IconBox from 'components/Icons/IconBox';
import { views } from "react-big-calendar/lib/utils/propTypes";

const reducer = (state, action) => {
  if (action.type === "SWITCH_ACTIVE") {
    if (action.payload === "overview") {
      const newState = {
        overview: true,
        teams: false,
        projects: false,
        newbranch:false,
      };
      return newState;
    } else if (action.payload === "teams") {
      const newState = {
        overview: false,
        teams: true,
        projects: false,
        newbranch:false,
      };
      return newState;
    } else if (action.payload === "projects") {
      const newState = {
        overview: false,
        teams: false,
        projects: true,
        newbranch:false,
      };
      return newState;
    } else if (action.payload === "newbranch") {
      const newState = {
        overview: false,
        teams: false,
        projects: false,
        newbranch: true,
      };
      return newState;
    }
  }
  return state;
};

function BranchDashboard(props) {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [state, dispatch] = useReducer(reducer, {
    overview: true,
    teams: false,
    projects: false,
  });
  const [passwordResetIsLoading, setPasswordResetIsLoading] = useState(false)

  const { colorMode } = useColorMode();

  // Chakra color mode
  const iconBlue = useColorModeValue('blue.500', 'blue.500');
  const iconBoxInside = useColorModeValue('white', 'white');
  const textColor = useColorModeValue("gray.700", "white");
  const iconColor = useColorModeValue("blue.500", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");

  const [name, setName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [emailAddress, setemailAddress] = useState("")
  const [IsLoaded, setIsLoaded] = useState(false)
  const [appCategories, setAppCategories] = useState([])
  const [appPermissions, setAppPermissions] = useState([])
  const [toastSettings, setToast] = useState([])
  const [branches, setBranches] = useState([])
  const [branchInfo, setBranchInfo] = useState({})
  const toast = useToast()
  const toastIdRef = useRef()


  const userId = props.location.pathname.split('/').at(-1)


  useEffect(() => {
    ApiService.getBranchInfo().then(data => {
      setBranchInfo(data)
    })
    ApiService.getBranches().then(data => {
      setBranches(data)
    }).then(() => {
      setIsLoaded(true)
    })
  }, [])

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px", lg: "100px" }}>
      <Flex
        direction={{ sm: "column", md: "row" }}
        mb="24px"
        maxH="330px"
        justifyContent={{ sm: "center", md: "space-between" }}
        align="center"
        backdropFilter="blur(21px)"
        boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
        border="1.5px solid"
        borderColor={borderProfileColor}
        bg={bgProfile}
        p="24px"
        borderRadius="20px"
      >
        <Flex
          direction={{ sm: "column", lg: "row" }}
          w={{ sm: "100%", md: "50%", lg: "auto" }}
        >
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex
              align="center"
              w={{ sm: "100%", lg: "135px" }}
              bg={
                state.overview
                  ? colorMode === "dark"
                    ? "navy.900"
                    : "#fff"
                  : null
              }
              borderRadius="8px"
              justifyContent="center"
              py="10px"
              boxShadow={
                state.overview ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null
              }
              cursor="pointer"
              transition="all .5s ease"
              onClick={() =>
                dispatch({ type: "SWITCH_ACTIVE", payload: "overview" })
              }
            >
              <Icon color={textColor} as={FaCube} me="6px" />
              <Text fontSize="xs" color={textColor} fontWeight="bold">
                OVERVIEW
              </Text>
            </Flex>
          </Button>
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex
              align="center"
              w={{ lg: "135px" }}
              borderRadius="8px"
              justifyContent="center"
              py="10px"
              mx={{ lg: "1rem" }}
              cursor="pointer"
              boxShadow={
                state.teams ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null
              }
              bg={
                state.teams
                  ? colorMode === "dark"
                    ? "navy.900"
                    : "#fff"
                  : null
              }
              transition="all .5s ease"
              onClick={() =>
                dispatch({ type: "SWITCH_ACTIVE", payload: "teams" })
              }
            >
              <Icon color={textColor} as={IoDocumentsSharp} me="6px" />
              <Text fontSize="xs" color={textColor} fontWeight="bold">
                USERS
              </Text>
            </Flex>
          </Button>
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex
              align="center"
              w={{ lg: "135px" }}
              borderRadius="8px"
              justifyContent="center"
              py="10px"
              cursor="pointer"
              boxShadow={
                state.projects ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null
              }
              bg={
                state.projects
                  ? colorMode === "dark"
                    ? "navy.900"
                    : "#fff"
                  : null
              }
              transition="all .5s ease"
              onClick={() =>
                dispatch({ type: "SWITCH_ACTIVE", payload: "projects" })
              }
            >
              <Icon color={textColor} as={FaPenFancy} me="6px" />
              <Text fontSize="xs" color={textColor} fontWeight="bold">
                PROPERTIES
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'
            >
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'
                >
                  Total Branches
                </StatLabel>
                <Flex>
                  {IsLoaded ? (
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                    {branchInfo.total_branches}
                  </StatNumber>
                  ):<Spinner />}
                </Flex>
              </Stat>
              <IconBox borderRadius='50%' h={'45px'} w={'45px'} bg={iconBlue}>
                <WalletIcon h={'24px'} w={'24px'} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{' '}
              </Text>
              Since last month
            </Text>
          </Flex>
        </Card>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'
            >
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'
                >
                  Total Properties
                </StatLabel>
                <Flex>
                  {IsLoaded ? (
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                    {branchInfo.total_properties}
                    </StatNumber>
                  ):<Spinner />}
                  
                </Flex>
              </Stat>
              <IconBox borderRadius='50%' h={'45px'} w={'45px'} bg={iconBlue}>
                <GlobeIcon h={'24px'} w={'24px'} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +5.2%{' '}
              </Text>
              Since last month
            </Text>
          </Flex>
        </Card>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'
            >
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'
                >
                  Total Enquiries
                </StatLabel>
                <Flex>
                  {IsLoaded ? (
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                    {branchInfo.total_enquiries}
                    </StatNumber>
                  ):<Spinner />}
                  
                </Flex>
              </Stat>
              <IconBox borderRadius='50%' h={'45px'} w={'45px'} bg={iconBlue}>
                <DocumentIcon h={'24px'} w={'24px'} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='red.500' fontWeight='bold'>
                -2.82%{' '}
              </Text>
              Since last month
            </Text>
          </Flex>
        </Card>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'
            >
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'
                >
                  Total Users
                </StatLabel>
                <Flex>
                  {IsLoaded ? (
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                    {branchInfo.total_users}
                    </StatNumber>
                  ):<Spinner />}
                </Flex>
              </Stat>
              <IconBox borderRadius='50%' h={'45px'} w={'45px'} bg={iconBlue}>
                <CartIcon h={'24px'} w={'24px'} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +8.12%{' '}
              </Text>
              Since last month
            </Text>
          </Flex>
        </Card>
      </SimpleGrid>
      {state.overview ? (
        <Card p="16px" my="24px">
        <CardHeader p="12px 5px" mb="12px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Branches
            </Text>
          </Flex>
        </CardHeader>
        <CardBody px="5px">
          <Grid
            templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
            templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
            gap="24px"
          >
            {IsLoaded ? (
              branches.map((branch) => (
                <BranchTile 
                  id={branch.id}
                  name={branch.name}
                  street={branch.street}
                  town={branch.town}
                  county={branch.county}
                  postcode={branch.postcode}
                  branch_image={branch.branch_image_url}
                  permission={branch.permission} />
              ))
            ): <Spinner />}
            
            <Button
              p="0px"
              bg="transparent"
              border="1px solid lightgray"
              borderRadius="15px"
              minHeight={{ sm: "200px", md: "100%" }}
              onClick={() =>
                dispatch({ type: "SWITCH_ACTIVE", payload: "newbranch" })
              }
            >
              <Flex direction="column" justifyContent="center" align="center">
                <Icon as={FaPlus} color={textColor} fontSize="lg" mb="12px" />
                <Text fontSize="lg" color={textColor} fontWeight="bold">
                  Create a new Branch
                </Text>
              </Flex>
            </Button>
          </Grid>
        </CardBody>
      </Card>
      ):null}
      {state.newbranch ? (
        <Wizard />
      ):null}
    </Flex>
  );
}

export default BranchDashboard;
