import axios from "axios";
import JwtService from 'hooks/useJwt';

//const BASE_URL = "http://localhost:8000/webapi"
const BASE_URL = "https://api.propertynest.co.uk/webapi"

const instance = axios.create({
  baseURL: BASE_URL,
  // headers: {
  //   "Content-Type": "application/json",
  // },
});

instance.interceptors.request.use(
  (config) => {
    const token = JwtService.getJwt()
    const refresh_token = JwtService.getRefreshToken()
    if(config.url !== "/login/refresh"){
      console.log(config.url)
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
    } else{
      config.headers["Authorization"] = 'Bearer ' + refresh_token;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/login/refresh" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        
        try {
          const refresh_token = JwtService.getRefreshToken()
          if (refresh_token){
            const rs = await instance.post("/login/refresh", {headers:{
              'Authorization': "Bearer "+refresh_token
            }});
            JwtService.updateLocalData(rs.data);
          }
          return instance(originalConfig);
        } catch (_error) {
          // window.location.href='/'
          console.log(_error)
          return Promise.reject(_error);
        }
      }
    }
    // window.location.href='/'
    return Promise.reject(err);
  }
);

export default instance;