/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useRef, useReducer } from 'react';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Image,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useColorMode,
  useColorModeValue,
  Spinner,
  GridItem,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Avatar,
  Badge,
  Td,
  useToast,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Progress,
} from '@chakra-ui/react';
// Assets
import productImage1 from 'assets/img/product-page-1.png';
import {
  FaCube,
  FaFacebook,
  FaInstagram,
  FaPenFancy,
  FaPlus,
  FaTwitter,
  FaUser,
} from "react-icons/fa";
import {
  AdobexdLogo,
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  JiraLogo,
  RocketIcon,
  SettingsIcon,
  SlackLogo,
  SpotifyLogo,
  WalletIcon,
} from 'components/Icons/Icons.js';
import { IoCloudyNight, IoDocumentsSharp } from "react-icons/io5";
// Custom components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Editor from 'components/Editor/Editor';
import PropertyTableSmall from 'components/Propertynest/PropertyTableSmall'
import { propertyColumnsSmall } from 'variables/columnsData';
import ApiService from 'hooks/useApi'
import { useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import avatar10 from 'assets/img/avatars/avatar10.png';
import avatar2 from 'assets/img/avatars/avatar2.png';
import avatar3 from 'assets/img/avatars/avatar3.png';
import avatar4 from 'assets/img/avatars/avatar4.png';
import avatar5 from 'assets/img/avatars/avatar5.png';
import handBg from 'assets/img/hand-background.png';
import teamsImage from 'assets/img/teams-image.png';
import TablesTableRow from 'components/Tables/TablesTableRow';
import AddUserToMeetTheTeam from 'components/Modals/AddUserToMeetTheTeam'
import AddFeedToBranch from 'components/Modals/AddFeedToBranch'
import BranchImageModal from 'components/Modals/BranchImage'
import bgCardReports from "assets/img/background-card-reports.png";
import IconBox from "components/Icons/IconBox";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { AiFillLike } from "react-icons/ai";
import { tablesReportsData } from "variables/general";
import TablesReportsRow from "components/Tables/TablesReportsRow";


const reducer = (state, action) => {
  if (action.type === "SWITCH_ACTIVE") {
    if (action.payload === "overview") {
      const newState = {
        overview: true,
        teams: false,
        properties: false,
        feeds: false,
      };
      return newState;
    } else if (action.payload === "teams") {
      const newState = {
        overview: false,
        teams: true,
        properties: false,
        feeds: false,
      };
      return newState;
    } else if (action.payload === "properties") {
      const newState = {
        overview: false,
        teams: false,
        properties: true,
        feeds: false,
      };
      return newState;
    }
    else if (action.payload === "feeds") {
      const newState = {
        overview: false,
        teams: false,
        properties: false,
        feeds: true,
      };
      return newState;
    }
  }
  return state;
};


function EditBranch(props) {
  const [propertyData, setPropertyData] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [branchData, setBranchData] = useState({})
  const [branchName, setBranchName] = useState('')
  const [branchStreet, setBranchStreet] = useState('')
  const [modifiedBranchData, setModifiedBranchData] = useState({})
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const [branchHierarchy, setBranchHierarchy] = useState([])
  const [branchHierarchyIsLoaded, setBranchHierarchyIsLoaded] = useState(false)
  const [toastSettings, setToast] = useState([])
  const [feedData, setFeedData] = useState()
  const toast = useToast()
  const toastIdRef = useRef()
  const { colorMode } = useColorMode();

  // Chakra color mode
  const iconBlue = useColorModeValue('blue.500', 'blue.500');
  const iconBoxInside = useColorModeValue('white', 'white');
  const iconColor = useColorModeValue("blue.500", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const secondaryColor = useColorModeValue("gray.400", "white");
  const bgProgress = useColorModeValue("gray.200", "navy.900");
  const [state, dispatch] = useReducer(reducer, {
    overview: true,
    teams: false,
    projects: false,
  });
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();

  const {
    isOpen: isOpen4,
    onOpen: onOpen4,
    onClose: onClose4,
  } = useDisclosure();
  const [skills, setSkills] = useState([
    {
      name: 'chakra-ui',
      id: 1,
    },
    {
      name: 'react',
      id: 2,
    },
    {
      name: 'javascript',
      id: 3,
    },
  ]);

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      setSkills([
        ...skills,
        {
          name: e.target.value,
          id: skills.length === 0 ? 1 : skills[skills.length - 1].id + 1,
        },
      ]);
      e.target.value = '';
    }
  };

  const textColor = useColorModeValue('gray.700', 'white');

  function handler(val){
    setBranchName(val)
  }

  function handleBranchDataChange(e){
    console.log(e.target)
    if(e.target.id == 'branch_name'){
      console.log(e.target.value)
      setBranchName(e.target.value)
    }
  }
  function handleOnDragEnd(result){
    console.log(result)
    if (!result.destination) return;

    const items = Array.from(branchHierarchy);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setBranchHierarchy(items)
    toastIdRef.current = toast({
      title: 'Updated "Meet the Team"',
      description: "Meet the team order updated successfully",
      status: 'success',
      duration: 9000,
      isClosable: true,
    })

  }

  function handleFeedUpdate(){
    ApiService.getBranchFeeds(props.match.params.id).then(data => {
      setFeedData(data)
    })
  }

  useEffect(() => {
    ApiService.getBranchById(props.match.params.id).then(data =>{
      console.log(data)
      setBranchData(data)
      setModifiedBranchData(data)
      setBranchName(data.name)
      const branch_h_order = []
      for(var i in data.branch_hierarchy){
        branch_h_order[data.branch_hierarchy[i].position_numerical] = data.branch_hierarchy[i]
      }
      setBranchHierarchy(branch_h_order)
      setBranchHierarchyIsLoaded(true)
    })
    ApiService.getPropertyInfoSmall(props.match.params.id).then(data => {
      setPropertyData(data)
    }).then(() => {
      ApiService.getBranchFeeds(props.match.params.id).then(data => {
        setFeedData(data)
      }).then(data => {
        setIsLoaded(true)
      })
      
    })
  },[])


  return (
    <Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
      <Flex
        direction={{ sm: "column", md: "row" }}
        mb="24px"
        maxH="330px"
        justifyContent={{ sm: "center", md: "space-between" }}
        align="center"
        backdropFilter="blur(21px)"
        boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
        border="1.5px solid"
        borderColor={borderProfileColor}
        bg={bgProfile}
        p="24px"
        borderRadius="20px"
      >
        <Flex
          direction={{ sm: "column", lg: "row" }}
          w={{ sm: "100%", md: "50%", lg: "auto" }}
        >
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex
              align="center"
              w={{ sm: "100%", lg: "135px" }}
              bg={
                state.overview
                  ? colorMode === "dark"
                    ? "navy.900"
                    : "#fff"
                  : null
              }
              borderRadius="8px"
              justifyContent="center"
              py="10px"
              boxShadow={
                state.overview ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null
              }
              cursor="pointer"
              transition="all .5s ease"
              onClick={() =>
                dispatch({ type: "SWITCH_ACTIVE", payload: "overview" })
              }
            >
              <Icon color={textColor} as={FaCube} me="6px" />
              <Text fontSize="xs" color={textColor} fontWeight="bold">
                OVERVIEW
              </Text>
            </Flex>
          </Button>
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex
              align="center"
              w={{ lg: "135px" }}
              borderRadius="8px"
              justifyContent="center"
              py="10px"
              mx={{ lg: "1rem" }}
              cursor="pointer"
              boxShadow={
                state.teams ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null
              }
              bg={
                state.teams
                  ? colorMode === "dark"
                    ? "navy.900"
                    : "#fff"
                  : null
              }
              transition="all .5s ease"
              onClick={() =>
                dispatch({ type: "SWITCH_ACTIVE", payload: "teams" })
              }
            >
              <Icon color={textColor} as={IoDocumentsSharp} me="6px" />
              <Text fontSize="xs" color={textColor} fontWeight="bold">
                USERS
              </Text>
            </Flex>
          </Button>
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex
              align="center"
              w={{ lg: "135px" }}
              borderRadius="8px"
              justifyContent="center"
              py="10px"
              mx={{ lg: "1rem" }}
              cursor="pointer"
              boxShadow={
                state.properties ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null
              }
              bg={
                state.properties
                  ? colorMode === "dark"
                    ? "navy.900"
                    : "#fff"
                  : null
              }
              transition="all .5s ease"
              onClick={() =>
                dispatch({ type: "SWITCH_ACTIVE", payload: "properties" })
              }
            >
              <Icon color={textColor} as={IoDocumentsSharp} me="6px" />
              <Text fontSize="xs" color={textColor} fontWeight="bold">
                PROPERTIES
              </Text>
            </Flex>
          </Button>
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex
              align="center"
              w={{ lg: "135px" }}
              borderRadius="8px"
              justifyContent="center"
              py="10px"
              mx={{ lg: "1rem" }}
              cursor="pointer"
              boxShadow={
                state.feeds ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null
              }
              bg={
                state.feeds
                  ? colorMode === "dark"
                    ? "navy.900"
                    : "#fff"
                  : null
              }
              transition="all .5s ease"
              onClick={() =>
                dispatch({ type: "SWITCH_ACTIVE", payload: "feeds" })
              }
            >
              <Icon color={textColor} as={IoDocumentsSharp} me="6px" />
              <Text fontSize="xs" color={textColor} fontWeight="bold">
                FEEDS
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
      <Flex
        direction={{ sm: 'column', lg: 'row' }}
        justify='space-between'
        align={{ lg: 'center' }}
        w='100%'
        mb={{ sm: '24px', lg: '55px' }}
      >
        <Flex direction='column'>
          
            {isLoaded ? (
              <Text
              color='white'
              ms='12px'
              fontSize={{ sm: 'xl', md: '2xl' }}
              fontWeight='bold'
              mb='10px'
            >{branchName}
              </Text>
            ):<Spinner />}
          
        </Flex>
      </Flex>
      {state.overview ? (
               <Grid templateColumns={{ sm: '1fr', lg: '1fr 1.7fr' }} gap='24px'>
               <Stack direction='column' spacing='24px'>
                 <Card>
                   <CardHeader mb='42px'>
                     <Text color={textColor} fontSize='lg' fontWeight='bold'>
                       Branch Image
                     </Text>
                   </CardHeader>
                   <CardBody>
                     <Flex direction='column' w='100%'>
                       <Box
                         w={{ sm: '280px', md: '670px', lg: '600px' }}
                         h={{ sm: '185px', md: '450px', lg: '400px' }}
                         maxW={{ sm: '280px', md: '670px', lg: '600px' }}
                         maxH={{ sm: '185px', md: '450px', lg: '400px' }}
                         mb='24px'
                       >
                         {isLoaded ? (
                           <Image src={branchData.branch_image_url} w='100%' h='100%' />
                         ):<Spinner />}
                         
                       </Box>
                       <Flex mt='40px'>
                         <BranchImageModal 
                         branch_id = {props.match.params.id}
                         />
                         <Button
                           variant='no-effects'
                           bg='gray.100'
                           w={{ sm: '75px', md: '100px' }}
                           h='35px'
                         >
                           <Text fontSize='xs' color='gray.700' fontWeight='bold'>
                             REMOVE
                           </Text>
                         </Button>
                       </Flex>
                     </Flex>
                   </CardBody>
                 </Card>
               </Stack>
               <Stack direction='column' spacing='24px'>
                 <Card>
                   <CardHeader mb='22px'>
                     <Text color={textColor} fontSize='lg' fontWeight='bold'>
                       Branch Information
                     </Text>
                   </CardHeader>
                   <CardBody>
                     <Stack direction='column' spacing='20px' w='100%'>
                       <Stack direction={{ sm: 'column', md: 'row' }} spacing='30px'>
                         <FormControl>
                           <FormLabel fontSize='xs' fontWeight='bold' mb='10px'>
                             Branch Name
                           </FormLabel>
                           {isLoaded ? (
                             <Input
                             variant='main'
                             placeholder='eg. Modern Luxury Sofa'
                             value={branchName}
                             id='branch_name'
                             fontSize='xs'
                             onChange={handleBranchDataChange}
                           />
                           ):<Spinner />}
                         </FormControl>
                         <FormControl>
                           <FormLabel fontSize='xs' fontWeight='bold' mb='10px'>
                             Street
                           </FormLabel>
                           {isLoaded ? (
                             <Input variant='main' placeholder='eg. 42' value={branchData.street} fontSize='xs' id='street' />
                           ):<Spinner />}
                         </FormControl>
                       </Stack>
                       <Stack direction={{ sm: 'column', lg: 'row' }} spacing='30px'>
                         <FormControl>
                         <FormLabel fontSize='xs' fontWeight='bold' mb='10px'>
                             Town
                           </FormLabel>
                           {isLoaded ? (
                             <Input variant='main' placeholder='eg. 42' value={branchData.town} fontSize='xs' id='town' />
                           ):<Spinner />}
                           
                         </FormControl>
                         <FormControl>
                         <FormLabel fontSize='xs' fontWeight='bold' mb='10px'>
                             County
                           </FormLabel>
                           {isLoaded ? (
                             <Input variant='main' placeholder='eg. 42' contentEditable='true' value={branchData.county} fontSize='xs' id='county'/>
                           ):<Spinner />}
                         </FormControl>
                         <FormControl>
                         <FormLabel fontSize='xs' fontWeight='bold' mb='10px'>
                             Postcode
                           </FormLabel>
                           {isLoaded ? (
                             <Input variant='main' placeholder='eg. 42' value={branchData.postcode} fontSize='xs' id='county'/>
                           ):<Spinner />}
                         </FormControl>
                       </Stack>
                       {isLoaded && branchData != modifiedBranchData ? (
                         <Stack spacing='30px' w='100%'>
                         <Button>Save Changes</Button>
                       </Stack>
                       ):null}
                     </Stack>
                   </CardBody>
                 </Card>
                 <Card>
                   <CardHeader mb='32px'>
                   <Flex spacing='4'>
                 <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
       
                   <Box>
                   <Text fontSize='lg' color={textColor} fontWeight='bold'>
                       Meet the Team
                     </Text>
                   </Box>
                 </Flex>
                 <AddUserToMeetTheTeam setBranchHierarchy={setBranchHierarchy} branchHierarchy={branchHierarchy} />
               </Flex>
                   </CardHeader>
                   <CardBody>
                     <Stack direction='column' spacing='20px' w='100%'>
                       <Stack direction='row' spacing='24px'>
                       <Flex direction='column' w='100%'>
                     {branchHierarchyIsLoaded ? (
                       <DragDropContext onDragEnd={handleOnDragEnd}>
                       <Droppable droppableId='team_members'>
                       {(provided) => (
                         <Table variant='simple' color={textColor} w='100%' className="team_members" {...provided.droppableProps} ref={provided.innerRef}>
                         <Thead>
                           <Tr my='.8rem' pl='0px' color='gray.400'>
                             <Th pl='0px' borderColor={borderColor} color='gray.400'>
                               Agent
                             </Th>
                             <Th borderColor={borderColor} color='gray.400'>
                               Role
                             </Th>
                             <Th borderColor={borderColor}></Th>
                           </Tr>
                         </Thead>
                         <Tbody>
                           {branchHierarchy.map(({id, first_name, last_name, position_role, profile_picture_url}, index) =>{
                             return(
                               <Draggable key={first_name} draggableId={first_name} index={index}>
                                 {(provided) => (
                                   <Tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                   <Td
                                     minWidth={{ sm: "250px" }}
                                     pl="0px"
                                     borderColor={borderColor}
                                     borderBottom={null}
                                   >
                                     <Flex
                                       align="center"
                                       py=".8rem"
                                       minWidth="100%"
                                       flexWrap="nowrap"
                                     >
                                       <Avatar src={profile_picture_url} w="50px" borderRadius="12px" me="18px" />
                                       <Flex direction="column">
                                         <Text
                                           fontSize="md"
                                           color={titleColor}
                                           fontWeight="bold"
                                           minWidth="100%"
                                         >
                                           {first_name} {last_name}
                                         </Text>
                                         <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                         </Text>
                                       </Flex>
                                     </Flex>
                                   </Td>
                             
                                   <Td borderColor={borderColor} borderBottom={null}>
                                     <Flex direction="column">
                                       <Text fontSize="md" color={textColor} fontWeight="bold">
                                       </Text>
                                       <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                         {position_role}
                                       </Text>
                                     </Flex>
                                   </Td>
                                   <Td borderColor={borderColor} borderBottom={null}>
                                     <Button p="0px" bg="transparent" variant="no-effects">
                                       <Text
                                         fontSize="md"
                                         color="gray.400"
                                         fontWeight="bold"
                                         cursor="pointer"
                                       >
                                         Remove
                                       </Text>
                                     </Button>
                                   </Td>
                                 </Tr>
                                 )}
                               </Draggable>
                             )
                           })}
                           {provided.placeholder}
                         </Tbody>
                       </Table>
                       )}
                   </Droppable>
                   </DragDropContext>
                     ):null}
                     
                       </Flex>
                      </Stack>
                     </Stack>
                   </CardBody>
                 </Card>
               </Stack>
               <GridItem w='100%' colSpan={2}>
                 <Card>
                   <CardHeader mb='32px'>
                     <Text fontSize='lg' color={textColor} fontWeight='bold'>
                       Properties
                     </Text>
                   </CardHeader>
                   <CardBody>
                     {isLoaded ? (
                       <PropertyTableSmall 
                       tableData={propertyData}
                       columnsData={propertyColumnsSmall}
                       />
                     ):<Spinner />}
                     
                   </CardBody>
                 </Card>
                 </GridItem>
             </Grid> 
      ) : null }
      {state.feeds ? (
        <Grid templateColumns={{ md: "repeat(2, 1fr)" }} gap="24px" mb="24px">
        <Grid
          templateColumns={{ md: "repeat(2, 1fr)" }}
          templateRows={{ md: "repeat(2, 1fr)" }}
          gap="24px"
        >
          <Card
            bgImage={
              colorMode === "light"
                ? bgCardReports
                : "linear-gradient(180deg, #3182CE 0%, #63B3ED 100%)"
            }
            minH="168px"
          >
            <CardBody h="100%">
              <Flex
                direction="column"
                justify="space-between"
                w="100%"
                h="100%"
              >
                <Flex justify="space-between" w="100%">
                  <IconBox bg="#fff" w="50px" h="50px">
                    <Icon as={FaUser} w="25px" h="25px" color="blue.900" />
                  </IconBox>
                </Flex>
                <Flex justify="space-between" w="100%">
                  <Flex direction="column">
                    <Text color="#fff" fontWeight="bold" fontSize="md">
                      1
                    </Text>
                    <Text
                      color={secondaryColor}
                      fontWeight="normal"
                      fontSize="sm"
                    >
                      Feeds Active
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </CardBody>
          </Card>
          <Card
            bgImage={
              colorMode === "light"
                ? bgCardReports
                : "linear-gradient(180deg, #3182CE 0%, #63B3ED 100%)"
            }
            minH="168px"
          >
            <CardBody h="100%">
              <Flex
                direction="column"
                justify="space-between"
                w="100%"
                h="100%"
              >
                <Flex justify="space-between" w="100%">
                  <IconBox bg="#fff" w="50px" h="50px">
                    <Icon as={RocketIcon} w="25px" h="25px" color="blue.900" />
                  </IconBox>
                </Flex>
                <Flex justify="space-between" w="100%">
                  <Flex direction="column">
                    <Text color="#fff" fontWeight="bold" fontSize="md">
                      357
                    </Text>
                    <Text
                      color={secondaryColor}
                      fontWeight="normal"
                      fontSize="sm"
                    >
                      Properties Imported
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </CardBody>
          </Card>
          <Card
            bgImage={
              colorMode === "light"
                ? bgCardReports
                : "linear-gradient(180deg, #3182CE 0%, #63B3ED 100%)"
            }
            minH="168px"
          >
            <CardBody h="100%">
              <Flex
                direction="column"
                justify="space-between"
                w="100%"
                h="100%"
              >
                <Flex justify="space-between" w="100%">
                  <IconBox bg="#fff" w="50px" h="50px">
                    <Icon as={CartIcon} w="25px" h="25px" color="blue.900" />
                  </IconBox>
                </Flex>
                <Flex justify="space-between" w="100%">
                  <Flex direction="column">
                    <Text color="#fff" fontWeight="bold" fontSize="md">
                      2340
                    </Text>
                    <Text
                      color={secondaryColor}
                      fontWeight="normal"
                      fontSize="sm"
                    >
                      Images Imported
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </Grid>
        <Card>
          <CardHeader mb="24px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Feed Statistics
            </Text>
          </CardHeader>
          <CardBody>
            <Flex direction="column" w="100%">
              <Stack direction="column" spacing="28px" w="100%" mb="40px">
                <Flex direction="column">
                  <Flex justify="space-between" mb="8px">
                    <Text fontSize="md" color="gray.400" fontWeight="500">
                      Properties Imported
                    </Text>
                    <Text fontSize="md" color="gray.400" fontWeight="500">
                      80%
                    </Text>
                  </Flex>
                  <Progress
                    colorScheme="blue"
                    size="xs"
                    bg={bgProgress}
                    value={80}
                    borderRadius="15px"
                  ></Progress>
                </Flex>
                <Flex direction="column">
                  <Flex justify="space-between" mb="8px">
                    <Text fontSize="md" color="gray.400" fontWeight="500">
                      Not Imported
                    </Text>
                    <Text fontSize="md" color="gray.400" fontWeight="500">
                      17%
                    </Text>
                  </Flex>
                  <Progress
                    bg={bgProgress}
                    colorScheme="gray"
                    size="xs"
                    value={17}
                    borderRadius="15px"
                  ></Progress>
                </Flex>
                <Flex direction="column">
                  <Flex justify="space-between" mb="8px">
                    <Text fontSize="md" color="gray.400" fontWeight="500">
                      Failed to Import
                    </Text>
                    <Text fontSize="md" color="gray.400" fontWeight="500">
                      3%
                    </Text>
                  </Flex>
                  <Progress
                    bg={bgProgress}
                    colorScheme="red"
                    size="xs"
                    value={3}
                    borderRadius="15px"
                  ></Progress>
                </Flex>
              </Stack>
              <Flex
                justify="space-between"
                w="100%"
                direction={{ sm: "column", lg: "row" }}
              >
                <AddFeedToBranch
                branch_id = {props.match.params.id}
                />
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Grid>
      ):null}
      {state.feeds ? (
        <Card overflowX={{ sm: "visible", lg: "visible" }}>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" ps="0px" color="gray.400">
                <Th ps="0px" color="gray.400" borderColor={borderColor}>
                  Feed Type
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Added by
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Last Updated
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Status
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Edit
                </Th>
              </Tr>
            </Thead>
            <Tbody pb="0px">
              {feedData.map((row, index, arr) => {
                return (
                  <TablesReportsRow
                    name={row.feed_type}
                    added_by={row.added_by}
                    last_updated={row.updated_at}
                    enabled={row.enabled}
                    review={row.id}
                    employed={row.employed}
                    id={row.id}
                    isLast={index === arr.length - 1 ? true : false}
                    key={index}
                    callback = {handleFeedUpdate}
                    feed_url = {row.feed_url}
                    branch_id = {props.match.params.id}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      ):null}
    </Flex>
  );
}

export default EditBranch;
