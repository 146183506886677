import { useDisclosure } from "@chakra-ui/react"
import { 
    Button, 
    Modal, 
    ModalOverlay, 
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Text,
    useColorModeValue,
    FormControl,
    FormLabel, 
    Input,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Stack,
} from "@chakra-ui/react"
import { useState } from "react"
import ApiService from 'hooks/useApi'

export default function BasicUsage(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [inviteButton, setInviteButton] = useState(false)
    const [emailAddress, setEmailAddress] = useState("")
    const [errMessage, setErrMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")


    function handleEmailChange(e){
        setEmailAddress(e.target.value)
      }

    function handleSubmit(e){
        e.preventDefault()
        setInviteButton(true)
        setErrMessage("")
        setSuccessMessage("")
        props.setBranchHierarchy('hi')
    }
    return (
      <>
        <Button
          variant='no-effects'
          bg={useColorModeValue('gray.700', 'white')}
          w='125px'
          h='35px'
          color={useColorModeValue('white', 'gray.700')}
          fontSize='xs'
          fontWeight='bold'
          alignSelf={{ sm: 'flex-start', lg: null }}
          mb={{ sm: '12px', md: '0px' }}
          onClick={onOpen}
        >
          Add
        </Button>
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add user to "Meet the Team"</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel>Email Address</FormLabel>
              <Input onChange={handleEmailChange} type="email" placeholder='Email Address' />
            </FormControl>
            </form>
            {errMessage != "" ?(
                <Stack spacing={3}>
                 <Alert borderRadius='8px' status='error' variant='subtle'>
              <AlertIcon />
              <AlertTitle mr='12px'>Error</AlertTitle>
              <AlertDescription>{errMessage}</AlertDescription>
            </Alert>
               </Stack>
            ):null}
            {successMessage != "" ?(
                <Alert borderRadius='8px' status='success' variant='subtle'>
                <AlertIcon />
                <AlertTitle mr='12px'>Success</AlertTitle>
                <AlertDescription>User invited successfully</AlertDescription>
              </Alert>
            ):null}
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='red' mr={3} onClick={onClose}>
                Close
              </Button>
              <Button 
              colorScheme='blue'
              disabled={inviteButton}
              onClick={handleSubmit}
              >Add User</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }