/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

export const columnsData1 = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "POSITION",
    accessor: "position",
  },
  {
    Header: "OFFICE",
    accessor: "office",
  },
  {
    Header: "AGE",
    accessor: "age",
  },
  {
    Header: "START DATE",
    accessor: "date",
  },
  {
    Header: "SALARY",
    accessor: "salary",
  },
];

export const columnsData2 = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "CUSTOMER",
    accessor: "customer",
  },
  {
    Header: "PRODUCT",
    accessor: "product",
  },
  {
    Header: "REVENUE",
    accessor: "revenue",
  },
];

export const userColumns = [
  {
    Header: "First Name",
    accessor: "first_name",
  },
  {
    Header: "Last Name",
    accessor: "last_name",
  },
  {
    Header: "Email Address",
    accessor: "email_address",
  },
  {
    Header: "Account Status",
    accessor:"account_status",
  },
  {
    Header: "Edit Account",
    accessor:"id",
  }
];

export const propertyColumnsSmall = [
  {
    Header: "Image",
    accessor: "image"
  },
  {
    Header: "Address Name",
    accessor: "address_name",
  },
  {
    Header:"Display Address",
    accessor: "address_display",
  },
  {
    Header: "View Property",
    accessor: 'id'
  }
]