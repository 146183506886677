import {
    Avatar,
    AvatarGroup,
    Button,
    Flex,
    Grid,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    useColorMode,
    useDisclosure,
  } from "@chakra-ui/react";
  import Card from "components/Card/Card";
  import CardBody from "components/Card/CardBody";
  import CardHeader from "components/Card/CardHeader";
import IconBox from 'components/Icons/IconBox';
import { HSeparator } from 'components/Separator/Separator';
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar5 from "assets/img/avatars/avatar5.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar7 from "assets/img/avatars/avatar7.png";
import { IoDocumentsSharp, IoEllipsisVerticalSharp } from "react-icons/io5";
import JwtService from 'hooks/useJwt'

import {
    AdobexdLogo,
    AtlassianLogo,
    JiraLogo,
    SlackLogo,
    SpotifyLogo,
  } from "components/Icons/Icons";

export default function AppCard(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
    const borderProfileColor = useColorModeValue("white", "transparent");
    const bgIconBox = useColorModeValue(
      "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
      "navy.700"
    );
    const secondaryColor = useColorModeValue("gray.500", "white");

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();

  const {
    isOpen: isOpen4,
    onOpen: onOpen4,
    onClose: onClose4,
  } = useDisclosure();

  const {
    isOpen: isOpen5,
    onOpen: onOpen5,
    onClose: onClose5,
  } = useDisclosure();

  function handleClick(){
    window.open(props.link, "_blank")
  }

  const userData = JwtService.getUserData()

    return (
        <Card minH="100%" alignSelf="flex-start" mr="20px" minH="100%">
        <CardHeader mb="18px">
          <Flex justify="space-between" w="100%">
            <Flex>
              <IconBox bg={bgIconBox} w="70px" h="70px" me="22px">
                <SlackLogo
                  w="40px"
                  h="40px"
                  alignSelf="center"
                  justifySelf="center"
                  transform="translate(5%)"
                />
              </IconBox>
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  mb="8px"
                >
                  {props.name}
                </Text>
              </Flex>
            </Flex>
            {userData.global_admin == 1 ? (
                            <Menu isOpen={isOpen1} onClose={onClose1}>
                            <MenuButton onClick={onOpen1} alignSelf="flex-start">
                              <Icon
                                as={IoEllipsisVerticalSharp}
                                color="gray.400"
                                w="20px"
                                h="20px"
                              />
                            </MenuButton>
                            <MenuList>
                              <MenuItem>Edit</MenuItem>
                              <MenuItem>Delete</MenuItem>
                            </MenuList>
                          </Menu>
            ):null }
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column">
            <Text color="gray.400" fontSize="sm" fontWeight="normal">
              {props.description}
            </Text>
            <HSeparator my="22px" />
            <Flex justify="space-between" w="100%">
              <Flex direction="column">
              </Flex>
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  mb="6px"
                >
                  <Button onClick={handleClick}>Launch</Button>
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    )
}