/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
// Assets
import CoverImage from "assets/img/CoverImage.png";
import React from "react";
import {useState} from 'react'
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import AuthCover from "layouts/AuthCover";
import { Redirect, useParams } from "react-router-dom";
import { last } from "lodash";
import ApiService from 'hooks/useApi'


const SignUpCover = props => {
  // Chakra color mode
  let { registerKey } = useParams() 
  const textColor = useColorModeValue("gray.400", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const colorIcons = useColorModeValue("gray.700", "white");
  const bgIcons = useColorModeValue("trasnparent", "navy.700");
  const bgIconsHover = useColorModeValue("gray.50", "whiteAlpha.100");
  const [firstName, setfirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [page, setPage] = useState(0)
  const [securityQuestion, setSecurityQuestion ] = useState('')
  const [securityQuestionAnswer, setSecurityQuestionAnswer] = useState('')
  const [registerError, setRegisterError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  function handleFirstName(e){
    setfirstName(e.target.value)
  }
  function handlelastName(e){
    setlastName(e.target.value)
  }
  function handleEmail(e){
    setEmail(e.target.value)
  }
  function handlePassword(e){
    setPassword(e.target.value)
  }
  function handleSecurityQuestion(e){
    setSecurityQuestion(e.target.value)
  }
  function handleSecurityQuestionAnswer(e){
    setSecurityQuestionAnswer(e.target.value)
  }

  function handleNext(e){
    setPage(page+1)
    setRegisterError('')
  }
  function handleBack(e){
    setPage(page-1)
    setRegisterError('')
  }
  function handleSubmit(e){
    e.preventDefault()
    ApiService.register(firstName, lastName, email, password, securityQuestion, securityQuestionAnswer, registerKey).then(response => {
      if(response?.detail){
        setRegisterError(response.detail)
      }else{
        props.history.push({
          pathname: '/auth',
          state: {message: "Your account has been created successfully. You can now login above."}
        })
      }
    })
      
  }

  return (
    <AuthCover image={CoverImage}>
      <Flex
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb="60px"
        mt={{ base: "60px", md: "160px" }}
      >
        <Flex
          zIndex="2"
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "100px" }}
          mb={{ base: "20px", md: "auto" }}
          bg={bgForm}
          boxShadow={useColorModeValue(
            "0px 5px 14px rgba(0, 0, 0, 0.05)",
            "unset"
          )}
        >
          {page == 0 ? (
            <FormControl>
            <FormLabel ms="4px" id="firstname" fontSize="sm" fontWeight="normal">
              First name
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="text"
              placeholder="Your first name"
              mb="24px"
              size="lg"
              onChange={handleFirstName}
              id='firstName'
              value={firstName}
              required
            />
            <FormLabel ms="4px" id="firstname" fontSize="sm" fontWeight="normal">
              Surname
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="text"
              placeholder="Your surname"
              mb="24px"
              size="lg"
              onChange={handlelastName}
              id='lastName'
              value={lastName}
              required
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Email
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="email"
              placeholder="Your full email adress"
              mb="24px"
              size="lg"
              onChange={handleEmail}
              id='email'
              value={email}
              required
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Password
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="password"
              placeholder="Your password"
              mb="24px"
              size="lg"
              onChange={handlePassword}
              id='password'
              value={password}
              required
            />
            <Button
              fontSize="10px"
              variant="dark"
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              onClick={handleNext}
            >
              NEXT
            </Button>
            {registerError != "" && 
              <Alert borderRadius='8px' status='error' variant='subtle'>
              <AlertIcon />
              <AlertTitle mr='12px'>Error</AlertTitle>
              <AlertDescription>{registerError}</AlertDescription>
            </Alert>
            }
          </FormControl>
          ): page == 1 ? (
            <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Select your security question:
            </FormLabel>
            <Select 
            variant="auth"
            fontSize="sm"
            ms="4px"
            mb="24px"
            size="lg"
            placeholder='Select security question'
            onChange={handleSecurityQuestion}
            required
            >
              <option value='mothers-maiden-name'>Mother's Maiden Name</option>
              <option value='first-pet'>First pet's name</option>
              <option value='city-born'>Name of the city you were born</option>
            </Select>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Security question answer:
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="password"
              placeholder="Answer to your security question"
              mb="24px"
              size="lg"
              onChange={handleSecurityQuestionAnswer}
              id="secQuestionAnswer"
              value={securityQuestionAnswer}
              required
            />
            <Button
              fontSize="10px"
              variant="light"
              fontWeight="bold"
              w="45%"
              h="45"
              mb="24px"
              mr="15px"
              onClick={handleBack}
            >
            BACK
            </Button>
            <Button
              fontSize="10px"
              variant="dark"
              fontWeight="bold"
              w="50%"
              h="45"
              mb="24px"
              onClick={handleSubmit}
            >
            REGISTER
            </Button>
          </FormControl>
          ):null}
          {registerError != "" && 
              <Alert borderRadius='8px' status='error' variant='subtle'>
              <AlertIcon />
              <AlertTitle mr='12px'>Error</AlertTitle>
              <AlertDescription>{registerError}</AlertDescription>
            </Alert>
            }
        </Flex>
      </Flex>
    </AuthCover>
  );
}

export default SignUpCover;
