import { useState, useRef, useEffect } from "react"
import ApiService from 'hooks/useApi'

import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Button,
    Input,
    useColorModeValue,
    Stack, 
    StackDivider, 
    StackItem,
    Skeleton,
    Box,
    Image,
    SimpleGrid,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'



export default function PropertyDrawer(props){
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()
    const [isLoaded, setIsLoaded] = useState(false)
    const [propData, setPropData] = useState()
    const [currentImage, setCurrentImage] = useState()
    const [images, setImages] = useState([])

    function handleOpen(e){
        onOpen()
        ApiService.getSingleProperty(props.property_id).then(data =>{
            setPropData(data)
            var images = []
            for(var i in data.files){
                if(data.files[i].type == 'image'){
                    images.push(data.files[i].url)
                }
            }
            setImages(images)
            setCurrentImage(images[0])
            setIsLoaded(true)
        })
    }

    return (
        <>
            <Button ref={btnRef} bg={useColorModeValue('gray.700', 'white')} color={useColorModeValue('white', 'gray.700')} onClick={handleOpen}>
        Open
      </Button>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        size='lg'
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Property Details</DrawerHeader>

          <DrawerBody>
            <Stack
            spacing={2}>
                {isLoaded ? (
                    <>
                    <Box>{propData.address_display}</Box>
                    <Box
                    w='100%'
                    h={{ sm: '200px', md: '500px', lg: '330px', xl: '500px' }}
                    mb='26px'
                    mx={{ sm: 'auto', lg: '0px' }}
                    >
                        <Image
                  src={currentImage}
                  w='100%'
                  h='100%'
                  borderRadius='8px'
                />
                </Box>
                <SimpleGrid
                columns='8'
                spacing='10px'
                >
                
                {images.map((image, index, arr)=> {
                    return(
                        <Box
                  w={{ sm: '36px', md: '90px', lg: '60px' }}
                  h={{ sm: '36px', md: '90px', lg: '60px' }}
                >
                  <Image
                    src={image}
                    w='100%'
                    h='100%'
                    borderRadius='8px'
                    cursor='pointer'
                    onClick={(e) => setCurrentImage(e.target.src)}
                  />
                </Box>
                    )
                })}</SimpleGrid>
                <Accordion>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box as='span' flex='1' textAlign='left'>
                                    Address Details
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                        <FormControl>
                        <FormLabel>Address Name</FormLabel>
                        <Input type='text' value={propData.address_name} isDisabled/>
                        </FormControl>
                        <FormControl>
                        <FormLabel>Address Street</FormLabel>
                        <Input type='text' value={propData.address_street} isDisabled/>
                        </FormControl>
                        <FormControl>
                        <FormLabel>Address Locality</FormLabel>
                        <Input type='text' value={propData.address_locality} isDisabled/>
                        </FormControl>
                        <FormControl>
                        <FormLabel>Address Town</FormLabel>
                        <Input type='text' value={propData.address_town} isDisabled/>
                        </FormControl>
                        <FormControl>
                        <FormLabel>Address County</FormLabel>
                        <Input type='text' value={propData.address_county} isDisabled/>
                        </FormControl>
                        <FormControl>
                        <FormLabel>Address Postcode</FormLabel>
                        <Input type='text' value={propData.address_postcode} isDisabled/>
                        </FormControl>
                    </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                    <h2>
                            <AccordionButton>
                                <Box as='span' flex='1' textAlign='left'>
                                    Price Details
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                    <FormControl>
                        <FormLabel>Price Qualifier</FormLabel>
                        <Input type='text' value={propData.price_qualifier} isDisabled/>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Price Display</FormLabel>
                        <Input type='text' value={propData.price_display} isDisabled/>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Price Text</FormLabel>
                        <Input type='text' value={propData.price_text} isDisabled/>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Rental Fees</FormLabel>
                        <Input type='text' value={propData.rental_fees} isDisabled/>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Lettings Fee</FormLabel>
                        <Input type='text' value={propData.lettings_fee} isDisabled/>
                    </FormControl>
                    <FormControl>
                        <FormLabel>RM Qualifier</FormLabel>
                        <Input type='text' value={propData.rm_qualifier} isDisabled/>
                    </FormControl>
                    </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                    <h2>
                            <AccordionButton>
                                <Box as='span' flex='1' textAlign='left'>
                                    Property Details
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <FormControl>
                                <FormLabel>Available</FormLabel>
                                <Input type='text' value={propData.available} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Uploaded</FormLabel>
                                <Input type='text' value={propData.uploaded} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Longitude</FormLabel>
                                <Input type='text' value={propData.longitude} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Latitude</FormLabel>
                                <Input type='text' value={propData.latitude} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Easting</FormLabel>
                                <Input type='text' value={propData.easting} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Northing</FormLabel>
                                <Input type='text' value={propData.northing} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Northing</FormLabel>
                                <Input type='text' value={propData.northing} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Streetview POV Latitude</FormLabel>
                                <Input type='text' value={propData.streetview_pov_latitude} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Streetview POV Longitude</FormLabel>
                                <Input type='text' value={propData.streetview_pov_longitude} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Streetview POV Pitch</FormLabel>
                                <Input type='text' value={propData.streetview_pov_pitch} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Streetview POV Heading</FormLabel>
                                <Input type='text' value={propData.streetview_pov_heading} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Streetview POV Zoom</FormLabel>
                                <Input type='text' value={propData.streetview_pov_zoom} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Web Status</FormLabel>
                                <Input type='text' value={propData.web_status} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Custom Status</FormLabel>
                                <Input type='text' value={propData.custom_status} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Comm Rent</FormLabel>
                                <Input type='text' value={propData.comm_rent} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Type</FormLabel>
                                <Input type='text' value={propData.type} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Furnished</FormLabel>
                                <Input type='text' value={propData.furnished} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>RM Type</FormLabel>
                                <Input type='text' value={propData.rm_type} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Let Bond</FormLabel>
                                <Input type='text' value={propData.let_bond} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>RM Let Type ID</FormLabel>
                                <Input type='text' value={propData.rm_let_type_id} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Bedrooms</FormLabel>
                                <Input type='text' value={propData.bedrooms} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Receptions</FormLabel>
                                <Input type='text' value={propData.receptions} isDisabled/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Bathrooms</FormLabel>
                                <Input type='text' value={propData.bathrooms} isDisabled/>
                            </FormControl>
                        </AccordionPanel>
                    </AccordionItem>                    
                </Accordion>
                    </>):(<Skeleton height={{ sm: '200px', md: '500px', lg: '330px', xl: '500px' }} />)}
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue'>Save</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
        </>
    )
}