/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  FormErrorMessage,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
// Assets
import CoverImage from "assets/img/CoverImage.png";
import React from "react";
import { Component, useState, setState} from 'react'
import AuthCover from "layouts/AuthCover";
import { Formik, Field } from "formik";
// import { postData, setCredentials, checkCredentials } from '../../../components/Auth/index';
import AuthService from '../../../hooks/useAuth';
import { Redirect} from 'react-router-dom';




const SignInCover = props => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.400", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const colorIcons = useColorModeValue("gray.700", "white");
  const bgIcons = useColorModeValue("trasnparent", "navy.700");
  const bgIconsHover = useColorModeValue("gray.50", "whiteAlpha.100");
  const [email, setEmail ] = useState('')
  const [password, setPassword] = useState('')
  const [loginErr, setLoginErr] = useState('')


  
  function handleSubmit(e){
    e.preventDefault();
    AuthService.login(email, password).then(() => {
      props.history.push("/admin/agentprofile");
    }).catch((error) => {
      setLoginErr(error.response)
    })
  }
  function handleEmailChange(e){
    setEmail(e.target.value)
  }
  function handlePasswordChange(e){
    setPassword(e.target.value)
  }
  console.log(props)
  return (
    <AuthCover image={CoverImage}>
      <Flex
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb="60px"
        mt={{ base: "60px", md: "160px" }}
      >
        <Flex
          zIndex="2"
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "100px" }}
          mb={{ base: "20px", md: "auto" }}
          bg={bgForm}
          boxShadow={useColorModeValue(
            "0px 5px 14px rgba(0, 0, 0, 0.05)",
            "unset"
          )}
        >
          <form onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Email Address
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="email"
              placeholder="Your email address"
              mb="24px"
              size="lg"
              value={email}
              onChange={handleEmailChange}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Password
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="password"
              placeholder="Your password"
              mb="24px"
              size="lg"
              value={password}
              onChange={handlePasswordChange}
            />
            <FormControl display="flex" alignItems="center" mb="24px">
              <Switch id="remember-login" colorScheme="blue" me="10px" />
              <FormLabel htmlFor="remember-login" mb="0" fontWeight="normal">
                Remember me
              </FormLabel>
            </FormControl>
            <Button
              fontSize="10px"
              variant="dark"
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              type="submit"
            >
              SIGN IN
            </Button>
          </FormControl>
          </form>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            {props.location?.state?.message && 
              <Alert borderRadius='8px' status='success' variant='subtle'>
              <AlertIcon />
              <AlertTitle mr='12px'>Success</AlertTitle>
              <AlertDescription>{props.location?.state?.message}</AlertDescription>
            </Alert>
            }
            {loginErr != "" && 
              <Alert borderRadius='8px' status='error' variant='subtle'>
              <AlertIcon />
              <AlertTitle mr='12px'>Error</AlertTitle>
              <AlertDescription>Invalid email address or password</AlertDescription>
            </Alert>
            }
          </Flex>
        </Flex>
      </Flex>
    </AuthCover>
  );
}

export default SignInCover;
