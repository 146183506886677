/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {
  Avatar,
  Flex,
  Icon,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  createIcon
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BsCircleFill } from "react-icons/bs";
import { IoDocumentsSharp, IoEllipsisVerticalSharp } from "react-icons/io5";
import DisableFeedModal from 'components/Modals/DisableFeedModal'

function TablesReportsRow(props) {
  const {
    image,
    name,
    email,
    enabled,
    review,
    employed,
    id,
    isLast,
    paddingY,
    added_by,
    last_updated,
  } = props;
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textColor = useColorModeValue("gray.700", "white");
  const secondaryColor = useColorModeValue("gray.400", "white");
  const mainColor = useColorModeValue("gray.500", "white");

  const CircleIcon = (props) => (
    <Icon viewBox='0 0 200 200' {...props}>
      <path
        fill='currentColor'
        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
      />
    </Icon>
  )

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();


  const { isDisableFeedOpen, onFeedDisableOpen, onDisableFeedClose } = useDisclosure()
  const [isFeedOpen, setIsFeedOpen] = useState(false)

  function handleDisableFeed(e){
    setIsFeedOpen(true)
  }

  return (
    <Tr border="none">
      <Td
        borderColor={borderColor}
        minW={{ sm: "220px", xl: "180px", "2xl": "220px" }}
        ps="0px"
        border={isLast ? "none" : null}
        px={{ xl: "2px", "2xl": "20px" }}
      >
        <Flex
          align="center"
          py={paddingY ? paddingY : ".8rem"}
          minWidth="100%"
          flexWrap="nowrap"
        >
          <Text
            fontSize={{ sm: "md", xl: "sm", "2xl": "md" }}
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {name}
          </Text>
        </Flex>
      </Td>

      <Td
        borderColor={borderColor}
        minW={{ sm: "150px", lg: "150px" }}
        border={isLast ? "none" : null}
        px={{ xl: "2px", "2xl": "20px" }}
      >
        <Flex direction="column">
          <Text
            fontSize={{ sm: "md", xl: "sm", "2xl": "md" }}
            color={mainColor}
            fontWeight="bold"
          >
            {added_by}
          </Text>
        </Flex>
      </Td>
      <Td
        borderColor={borderColor}
        minW={{ sm: "150px", lg: "120px" }}
        border={isLast ? "none" : null}
        px={{ xl: "2px", "2xl": "20px" }}
      >
        <Flex align="center">
          {/* <Icon
            as={BsCircleFill}
            w="8px"
            h="8px"
            color={
              review === "Positive"
                ? "teal.300"
                : review === "Negative"
                ? "red.500"
                : "gray.700"
            }
            me="6px"
          /> */}
          <Text
            color={secondaryColor}
            fontSize={{ sm: "md", xl: "sm", "2xl": "md" }}
          >
            {last_updated}
          </Text>
        </Flex>
      </Td>
      <Td
        borderColor={borderColor}
        minW={{ sm: "200px", lg: "170px" }}
        border={isLast ? "none" : null}
        px={{ xl: "2px", "2xl": "20px" }}
      >
        <Text
          fontSize={{ sm: "md", xl: "sm", "2xl": "md" }}
          color={secondaryColor}
          fontWeight="normal"
          pb=".5rem"
        >
          {enabled ? (<CircleIcon color='green.500' />) : (<CircleIcon color='red.500' />)}
        </Text>
      </Td>
      <Td
        borderColor={borderColor}
        minW={{ sm: "150px", lg: "170px" }}
        border={isLast ? "none" : null}
        px={{ xl: "2px", "2xl": "20px" }}
      >
         <Menu isOpen={isOpen1} onClose={onClose1}>
                            <MenuButton onClick={onOpen1} alignSelf="flex-start" >
                              <Icon
                                as={IoEllipsisVerticalSharp}
                                color="gray.400"
                                w="20px"
                                h="20px"
                              />
                            </MenuButton>
                            <MenuList>
                              {enabled ? (<MenuItem value={id} onClick={handleDisableFeed}>Disable</MenuItem>):(<MenuItem onClick={handleDisableFeed}>Enable</MenuItem>)}
                              <DisableFeedModal 
                              isOpen = {isFeedOpen}
                              onOpen = {onFeedDisableOpen}
                              onClose = {onDisableFeedClose}
                              disableState = {setIsFeedOpen}
                              currentState = {enabled}
                              feedType = {name}
                              callback = {props.callback}
                              branch_id = {props.branch_id}
                              feed_id = {id}
                              feed_url = {props.feed_url}
                              />
                              <MenuItem value={id}>Edit</MenuItem>
                              <MenuItem value={id}>Delete</MenuItem>
                              <MenuItem value={id}>Logs</MenuItem>
                            </MenuList>
                          </Menu>
      </Td>
    </Tr>
  );
}

export default TablesReportsRow;
