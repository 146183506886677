import { HamburgerIcon } from '@chakra-ui/icons';
// chakra imports
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	Flex,
	HStack,
	Icon,
	List,
	ListItem,
	Stack,
	Text,
	useColorModeValue,
	useDisclosure
} from '@chakra-ui/react';
import IconBox from 'components/Icons/IconBox';
import {
	renderThumbDark,
	renderThumbLight,
	renderTrack,
	renderTrackRTL,
	renderView,
	renderViewRTL
} from 'components/Scrollbar/Scrollbar';
import { HSeparator } from 'components/Separator/Separator';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FaCircle, FaUser, FaColumns, FaBuilding, FaFileAlt } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';
import ApiService from 'hooks/useApi';

import {
    CartIcon,
    DocumentIcon,
    HomeIcon,
    PersonIcon,
    StatsIcon,
  } from "components/Icons/Icons";

export default function DefaultLinks(props){
    const [sitePermissions, setSitePermissions] = useState({})
    const [sidebarLoaded, setSidebarLoaded] = useState(false)

    useEffect(()=>{
        console.log('sidebar load')
        ApiService.getPermissions().then(response => {
            const permState = {}
            for(const i in response.site_permissions){
                console.log(response.site_permissions[i])
                var elementName = response.site_permissions[i].site_element
                permState[elementName] = response.site_permissions[i].status
            }
            setSitePermissions(permState)
            setSidebarLoaded(true)
        })
    }, [])

    const key="sidenav"
    let variantChange = '0.2s linear';
    const activeRoute = (routeName) => {
		return location.hash.includes(routeName);
	};
    let activeBg = 'blue.500';
	let inactiveBg = useColorModeValue('transparent', 'navy.700');
	let activeColor = useColorModeValue('gray.700', 'white');
	let inactiveColor = useColorModeValue('gray.400', 'gray.400');
	let activeAccordionBg = useColorModeValue('white', 'navy.700');
	let sidebarActiveShadow = useColorModeValue('0px 7px 11px rgba(0, 0, 0, 0.04)', 'none');
	let activeColorIcon = 'white';
	let inactiveColorIcon = 'blue.500';
	let sidebarBackgroundColor = useColorModeValue('white', 'navy.900');
    return (
        <Box key={key}>
						<Text
							fontSize={'md'}
							color={activeColor}
							fontWeight='bold'
							mx='auto'
							ps={{
								sm: '10px',
								xl: '16px'
							}}
							pt='18px'
							pb='12px'>
						</Text>
                        {sidebarLoaded ? (
                            <>
                            {sitePermissions?.agent_profile == 1 ? (
                                <NavLink key='agent_profile' to={'/admin/agentprofile'}>
                                {activeRoute('/admin/agentprofile') ? (
                                    <HStack spacing='14px' py='15px' px='15px'>
                                        <IconBox bg='blue.500' color='white' h='30px' w='30px' transition={variantChange}>
                                            <FaUser />
                                        </IconBox>
                                        <Text
                                            color={activeRoute('/admin/agentprofile') ? activeColor : inactiveColor}
                                            fontWeight={activeRoute('/admin/agentsprofile') ? 'bold' : 'normal'}
                                            fontSize='sm'>
                                            Agent Profile
                                        </Text>
                                    </HStack>
                                ) : (
                                    <HStack spacing='14px' py='15px' px='15px'>
                                        <IconBox bg={inactiveBg} color={inactiveColorIcon} h='30px' w='30px' transition={variantChange}>
                                            <FaUser />
                                        </IconBox>
                                        <Text
                                            color={activeRoute('/admin/agentprofile') ? activeColor : inactiveColor}
                                            fontWeight={activeRoute('/admin/agentprofile') ? 'bold' : 'normal'}
                                            fontSize='sm'>
                                            Agent Profile
                                        </Text>
                                    </HStack>
                                )}
                            </NavLink>
                            ):null}
                            {sitePermissions?.apps_dashboard == 1 ? (
                                 <NavLink to={'/admin/dashboard/apps'}>
                                 <Box>
                                     {activeRoute('dashboard/apps') ? (
                                         <HStack spacing='14px' py='15px' px='15px'>
                                             <IconBox bg='blue.500' color='white' h='30px' w='30px' transition={variantChange}>
                                                 <FaColumns />
                                             </IconBox>
                                             <Text
                                                 color={activeRoute('dashboard/apps') ? activeColor : inactiveColor}
                                                 fontWeight={activeRoute('dashboard/apps') ? 'bold' : 'normal'}
                                                 fontSize='sm'>
                                                 Apps Dashboard
                                             </Text>
                                         </HStack>
                                     ) : (
                                         <HStack spacing='14px' py='15px' px='15px'>
                                             <IconBox bg={inactiveBg} color={inactiveColorIcon} h='30px' w='30px' transition={variantChange}>
                                                 <FaColumns />
                                             </IconBox>
                                             <Text
                                                 color={activeRoute('dashboard/apps') ? activeColor : inactiveColor}
                                                 fontWeight={activeRoute('dashboard/apps') ? 'bold' : 'normal'}
                                                 fontSize='sm'>
                                                 Apps Dashboard
                                             </Text>
                                         </HStack>
                                     )}
                                 </Box>
                             </NavLink>
                            ):null}
                            {sitePermissions?.branch_dashboard == 1 ? (
                                <NavLink to={'/admin/dashboard/branches'}>
                                <Box>
                                    {activeRoute('/dashboard/branches') ? (
                                        <HStack spacing='14px' py='15px' px='15px'>
                                            <IconBox bg='blue.500' color='white' h='30px' w='30px' transition={variantChange}>
                                                <FaBuilding />
                                            </IconBox>
                                            <Text
                                                color={activeRoute('/dashboard/branches') ? activeColor : inactiveColor}
                                                fontWeight={activeRoute('/dashboard/branches') ? 'bold' : 'normal'}
                                                fontSize='sm'>
                                                Branch Dashboard
                                            </Text>
                                        </HStack>
                                    ) : (
                                        <HStack spacing='14px' py='15px' px='15px'>
                                            <IconBox bg={inactiveBg} color={inactiveColorIcon} h='30px' w='30px' transition={variantChange}>
                                                <FaBuilding />
                                            </IconBox>
                                            <Text
                                                color={activeRoute('/dashboard/branches') ? activeColor : inactiveColor}
                                                fontWeight={activeRoute('/dashboard/branches') ? 'bold' : 'normal'}
                                                fontSize='sm'>
                                                Branch Dashboard
                                            </Text>
                                        </HStack>
                                    )}
                                </Box>
                            </NavLink>
                            ):null}
                            {sitePermissions?.property_dashboard == 1 ? (
                                <NavLink to={'/admin/dashboard/properties'}>
                                <Box>
                                    {activeRoute('/dashboard/properties') ? (
                                        <HStack spacing='14px' py='15px' px='15px'>
                                            <IconBox bg='blue.500' color='white' h='30px' w='30px' transition={variantChange}>
                                                <FaBuilding />
                                            </IconBox>
                                            <Text
                                                color={activeRoute('/dashboard/properties') ? activeColor : inactiveColor}
                                                fontWeight={activeRoute('/dashboard/properties') ? 'bold' : 'normal'}
                                                fontSize='sm'>
                                                Property Dashboard
                                            </Text>
                                        </HStack>
                                    ) : (
                                        <HStack spacing='14px' py='15px' px='15px'>
                                            <IconBox bg={inactiveBg} color={inactiveColorIcon} h='30px' w='30px' transition={variantChange}>
                                                <FaBuilding />
                                            </IconBox>
                                            <Text
                                                color={activeRoute('/dashboard/properties') ? activeColor : inactiveColor}
                                                fontWeight={activeRoute('/dashboard/properties') ? 'bold' : 'normal'}
                                                fontSize='sm'>
                                                Property Dashboard
                                            </Text>
                                        </HStack>
                                    )}
                                </Box>
                            </NavLink>
                            ):null}
                            {sitePermissions?.instructions_dashboard == 1 ? (
                                <NavLink to={'/admin/dashboard/instructions'}>
                                <Box>
                                    {activeRoute('/dashboard/instructions') ? (
                                        <HStack spacing='14px' py='15px' px='15px'>
                                            <IconBox bg='blue.500' color='white' h='30px' w='30px' transition={variantChange}>
                                                <FaFileAlt />
                                            </IconBox>
                                            <Text
                                                color={activeRoute('/dashboard/instructions') ? activeColor : inactiveColor}
                                                fontWeight={activeRoute('/dashboard/instructions') ? 'bold' : 'normal'}
                                                fontSize='sm'>
                                                Instructions Dashboard
                                            </Text>
                                        </HStack>
                                    ) : (
                                        <HStack spacing='14px' py='15px' px='15px'>
                                            <IconBox bg={inactiveBg} color={inactiveColorIcon} h='30px' w='30px' transition={variantChange}>
                                                <FaFileAlt />
                                            </IconBox>
                                            <Text
                                                color={activeRoute('/dashboard/instructions') ? activeColor : inactiveColor}
                                                fontWeight={activeRoute('/dashboard/instructions') ? 'bold' : 'normal'}
                                                fontSize='sm'>
                                                Instructions Dashboard
                                            </Text>
                                        </HStack>
                                    )}
                                </Box>
                            </NavLink>
                            ): null}
                           </>
                        ):null}
					</Box>
						
    )
}