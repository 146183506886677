/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Select,
  Stack,
  Switch,
  Table,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorMode,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  useInterval,
  Spacer,
  useDisclosure,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from '@chakra-ui/react';
import avatar4 from 'assets/img/avatars/avatar4.png';
// Custom components
import IconBox from 'components/Icons/IconBox';
import { MastercardIcon, VisaIcon } from 'components/Icons/Icons';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import {
  AtlassianLogo,
  InvisionLogo,
  SlackLogo,
  SpotifyLogo,
} from 'components/Icons/Icons';
import { HSeparator } from 'components/Separator/Separator';
import React, { useEffect, useState, Lorem } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { BsArrowRight, BsCircleFill, BsToggleOn } from 'react-icons/bs';
import { FaCube, FaUser, FaPencilAlt, FaFilePdf } from 'react-icons/fa';
import { GiSmartphone } from 'react-icons/gi';
import { IoIosArrowUp, IoIosRocket, IoMdNotifications } from 'react-icons/io';
import { IoDocumentText } from 'react-icons/io5';
import { MdPowerSettingsNew } from 'react-icons/md';
import { RiComputerLine } from 'react-icons/ri';
import { Element, Link } from 'react-scroll';
import ApiService from 'hooks/useApi';
import { CheckCircleIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useCalendlyEventListener, InlineWidget } from "react-calendly";

function Settings() {
  const bgHoverLinks = useColorModeValue('gray.100', 'navy.900');
  const secondaryColor = useColorModeValue('gray.500', 'white');
  const bgVerificationCard = useColorModeValue('gray.100', 'navy.700');
  const textColor = useColorModeValue('gray.700', 'white');
  const bgSkillsInput = useColorModeValue('white', 'navy.900');
  const bgSkill = useColorModeValue('gray.700', 'blue.500');
  const borderColor = useColorModeValue('gray.200', 'transparent');
  const borderTableColor = useColorModeValue('gray.200', 'gray.600');
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { colorMode } = useColorMode();

  const [activeButtons, setActiveButtons] = useState({
    messages: true,
    social: false,
    notifications: false,
    backup: false,
  });
  const [skills, setSkills] = useState([
    {
      name: 'chakra-ui',
      id: 1,
    },
    {
      name: 'react',
      id: 2,
    },
    {
      name: 'javascript',
      id: 3,
    },
  ]);

  const [toggle, setToggle] = useState(false);
  const [emailAddress, setemailAddress] = useState("dale.ruane@gmail.com")
  const [basicInfoComplete, setBasicInfoComplete] = useState(false)
  const [accountHolder, setAccountHolder] = useState()
  const [businessName, setBusinessName] = useState()
  const [companyRegistrationNumber, setCompanyRegistrationNumber] = useState()
  const [director1, setDirector1] = useState()
  const [director2, setDirector2] = useState()
  const [businessLine1, setBusinessLine1] = useState()
  const [businessLine2, setBusinessLine2] = useState()
  const [businessCity, setBusinessCity] = useState()
  const [businessPostcode, setBusinessPostcode] = useState()
  const [vatNumber, setVATNumber] = useState()
  const [basicInfoIsLoading, setBasicInfoIsLoading] = useState(false)
  const [onboardingStatus, setOnboardingStatus] = useState({})
  const [onboardingPerms, setOnboardingPerms] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => console.log(e.data.payload),
  });

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      setSkills([
        ...skills,
        {
          name: e.target.value,
          id: skills.length === 0 ? 1 : skills[skills.length - 1].id + 1,
        },
      ]);
      e.target.value = '';
    }
  };
  useEffect(()=>{
    ApiService.getOnboardingStatus().then(response =>{
      setOnboardingStatus(response)
      console.log(response)
      if(response.basic_info == true){
        setBasicInfoComplete(true)
      }
    }).then(()=>{
      ApiService.getOnboardingPerms().then(response => {
        setOnboardingPerms(response)
        setIsLoading(true)
      })
    })
  }, [])

  function handleBasicInfoSubmit(e){
    e.preventDefault()
    setBasicInfoIsLoading(true)
    ApiService.submitBasicInfo(
      accountHolder,
      businessName,
      emailAddress,
      companyRegistrationNumber,
      director1,
      director2,
      vatNumber,
      businessLine1,
      businessLine2,
      businessCity,
      businessPostcode
    ).then(result => {
      setBasicInfoComplete(true)
    })
  }
  function handleAccountHolder(e){
    setAccountHolder(e.target.value)
    console.log(accountHolder)
  }
  function handleBusinessName(e){
    setBusinessName(e.target.value)
  }
  function handleCompanyRegistrationNumber(e){
    setCompanyRegistrationNumber(e.target.value)
  }
  function handleDirector1(e){
    setDirector1(e.target.value)
  }
  function handleDirector2(e){
    setDirector2(e.target.value)
  }
  function handleBusinessLine1(e){
    setBusinessLine1(e.target.value)
  }
  function handleBusinessLine2(e){
    setBusinessLine2(e.target.value)
  }
  function handleBusinessCity(e){
    setBusinessCity(e.target.value)
  }
  function handleBusinessPostcode(e){
    setBusinessPostcode(e.target.value)
  }
  function handleVATNumber(e){
    setVATNumber(e.target.value)
  }

  return (
    <Flex direction='column' mt={{ sm: "150px", md: "100px" }}>
      <Box w='100%'>
        <Stack
          direction={{ sm: 'column', lg: 'row' }}
          spacing={{ sm: '8px', lg: '38px' }}
          w={{ sm: '100%', lg: null }}
          mb="24px"
          bg={bgProfile}
          maxH="330px"
          p="24px"
          borderRadius="20px"
        >
          <Button
            variant={
              activeButtons.messages
                ? colorMode === 'light'
                  ? 'light'
                  : 'primary'
                : 'no-effects'
            }
            boxShadow={
              activeButtons.messages
                ? '0px 2px 5.5px rgba(0, 0, 0, 0.06)'
                : 'none'
            }
            transition='all .5s ease'
            w={{ sm: '100%', lg: '135px' }}
            h='35px'
            _focus={{ boxShadow: '0px 2px 5.5px rgba(0, 0, 0, 0.06)' }}
            _active={{
              boxShadow:
                activeButtons.messages && '0px 2px 5.5px rgba(0, 0, 0, 0.06)',
            }}
            onClick={() =>
              setActiveButtons({
                messages: true,
                social: false,
                notifications: false,
                backup: false,
              })
            }
          >
            <Text
              color={!activeButtons.messages && 'white'}
              fontWeight='bold'
              fontSize='xs'
            >
              ONBOARDING
            </Text>
          </Button>
        </Stack>
      </Box>
      <Card
        w={{ sm: '100%', lg: '262px', xl: '21%', '2xl': '23.4%' }}
        mt={{ sm: '30px', lg: '0px' }}
        position={{ lg: 'fixed' }}
        top={{ lg: '275px' }}
      >
        <CardBody>
          {isLoading ? (
            <Stack direction='column' spacing='8px' w='100%' color='gray.500'>
            <Heading
            as="h4"
            size='md'
            >Onboarding Checklist</Heading>
            <Text>Agent Profile</Text>
          <Link to='info' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={IoIosRocket}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Basic Info
                  </Text>
                </Flex>
                {onboardingStatus.basic_info ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
                
              </Button>
            </Link>
            <Text>Discovery</Text>
            {onboardingPerms.discovery ? (
              <Link to='discovery-call' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={IoIosRocket}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Book Discovery Call
                  </Text>
                </Flex>
                {onboardingStatus.discovery_call ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            ):(
              <Link to='discovery-call' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
                isDisabled
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={IoIosRocket}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Book Discovery Call
                  </Text>
                </Flex>
                {onboardingStatus.discovery_call ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            )}
            {onboardingPerms.discovery ? (
              <Link to='non-disclosure-agreement' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={IoDocumentText}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Sign Non-disclosure Agreement
                  </Text>
                </Flex>
                {onboardingStatus.non_disclosure_agreement ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            ):(
              <Link to='non-disclosure-agreement' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
                isDisabled
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={IoDocumentText}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Sign Non-disclosure Agreement
                  </Text>
                </Flex>
                {onboardingStatus.non_disclosure_agreement ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            )}
            
            <Text>Discovery Session</Text>
            {onboardingPerms.discovery_session ? (
              <Link to='discovery-session' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={FaCube}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Book Discovery Session
                  </Text>
                </Flex>
                {onboardingStatus.discovery_session ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            ):(
              <Link to='discovery-session' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
                isDisabled
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={FaCube}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Book Discovery Session
                  </Text>
                </Flex>
                {onboardingStatus.discovery_session ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            )}
            
            <Text>Business Planning</Text>
            {onboardingPerms.business_planning ? (
              <Link to='application-form' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={BsToggleOn}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Application Form
                  </Text>
                </Flex>
                {onboardingStatus.application_form ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            ):(
              <Link to='application-form' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
                isDisabled
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={BsToggleOn}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Application Form
                  </Text>
                </Flex>
                {onboardingStatus.application_form ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            )}
            {onboardingPerms.business_planning ? (
              <Link to='franchisee-playbook' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={FaUser}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Franchisee Playbook
                  </Text>
                </Flex>
                {onboardingStatus.franchisee_playbook ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            ):(
              <Link to='franchisee-playbook' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
                isDisabled
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={FaUser}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Franchisee Playbook
                  </Text>
                </Flex>
                {onboardingStatus.franchisee_playbook ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            )}
            
            <Text>Final Meeting</Text>
            {onboardingPerms.final_meeting ? (
              <Link to='head-office-meeting' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={IoMdNotifications}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Book Head Office Meeting
                  </Text>
                </Flex>
                {onboardingStatus.head_office_meeting ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            ):(
              <Link to='head-office-meeting' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
                isDisabled
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={IoMdNotifications}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Book Head Office Meeting
                  </Text>
                </Flex>
                {onboardingStatus.head_office_meeting ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            )}
            {onboardingPerms.final_meeting ? (
              <Link to='territory-reservation-agreement' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={MdPowerSettingsNew}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Territory Reservation Agreement
                  </Text>
                </Flex>
                {onboardingStatus.territory_reservation_agreement ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            ):(
              <Link to='territory-reservation-agreement' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
                isDisabled
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={MdPowerSettingsNew}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Territory Reservation Agreement
                  </Text>
                </Flex>
                {onboardingStatus.territory_reservation_agreement ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            )}
            {onboardingPerms.final_meeting ? (
              <Link to='franchise-offer' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={AiFillDelete}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Franchise Offer
                  </Text>
                </Flex>
                {onboardingStatus.franchise_offer ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            ):(
              <Link to='franchise-offer' spy={true} smooth={true} duration={500}>
              <Button
                variant='no-effects'
                _hover={{ bg: bgHoverLinks }}
                w='100%'
                isDisabled
              >
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={AiFillDelete}
                    me='12px'
                    w='18px'
                    h='18px'
                    color={textColor}
                  />
                  <Text color='gray.500' fontWeight='normal' fontSize='xs'>
                    Franchise Offer
                  </Text>
                </Flex>
                {onboardingStatus.franchise_offer ? (
                  <CheckIcon/>
                ):(
                  <CloseIcon />
                )}
              </Button>
            </Link>
            )}
            
          </Stack>
          ):null}
          
        </CardBody>
      </Card>
      {isLoading ? (
        <Stack
        direction='column'
        spacing='24px'
        mt='40px'
        align={{ lg: 'flex-end' }}
        justify={{ lg: 'flex-end' }}
        w='100%'
      >
        <Card
          w={{ sm: '100%', lg: '70%' }}
          alignSelf='flex-end'
          justifySelf='flex-end'
        >
          {basicInfoComplete ? (
            <Element id='info' name='info'>
              <CardHeader mb='40px'>
              <Text color={textColor} fontSize='lg' fontWeight='bold'>
                Basic Info
              </Text>
            </CardHeader>
            <Alert
            status='success'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            height='200px'
          > 
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Information submitted!
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            Thanks for submitting your information. You'll receive an email when we're ready for you to proceed to the next step.
          </AlertDescription>
          </Alert>
          </Element>
          ): (
            <Element id='info' name='info'>
            <CardHeader mb='40px'>
              <Text color={textColor} fontSize='lg' fontWeight='bold'>
                Basic Info
              </Text>
            </CardHeader>
            <CardBody>
              <Stack direction='column' spacing='20px' w='100%'>
                <Stack direction='row' spacing={{ sm: '24px', lg: '30px' }}>
                  <FormControl>
                    <FormLabel fontWeight='semibold' fontSize='xs' mb='10px'>
                      Account Holder
                    </FormLabel>
                    <Input
                      variant='main'
                      placeholder='eg. Michael Smith'
                      fontSize='xs'
                      id='account_holder'
                      onChange={handleAccountHolder}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight='semibold' fontSize='xs' mb='10px'>
                      Business Name
                    </FormLabel>
                    <Input
                      variant='main'
                      placeholder='eg. Smith Lettings Ltd'
                      fontSize='xs'
                      id='business_name'
                      isRequired
                      onChange={handleBusinessName}
                    />
                  </FormControl>
                </Stack>
                <Stack direction='row' spacing={{ sm: '24px', lg: '30px' }}>
                  <FormControl>
                    <FormLabel fontWeight='semibold' fontSize='xs' mb='10px'>
                      Email Address
                    </FormLabel>
                    <Input
                      variant='main'
                      placeholder='eg. esthera@address.com'
                      fontSize='xs'
                      readOnly
                      isReadOnly
                      value={emailAddress}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight='semibold' fontSize='xs' mb='10px'>
                      Company Registration Number
                    </FormLabel>
                    <Input
                      variant='main'
                      placeholder='eg. 01234567'
                      fontSize='xs'
                      isRequired
                      id='company-registration-number'
                      onChange={handleCompanyRegistrationNumber}
                    />
                  </FormControl>
                </Stack>
                <Stack direction='row' spacing={{ sm: '24px', lg: '30px' }}>
                  <FormControl>
                    <FormLabel fontWeight='semibold' fontSize='xs' mb='10px'>
                      Company Director 1
                    </FormLabel>
                    <Input
                      variant='main'
                      placeholder='eg. John Doe'
                      fontSize='xs'
                      isRequired
                      onChange={handleDirector1}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight='semibold' fontSize='xs' mb='10px'>
                      Company Director 2
                    </FormLabel>
                    <Input
                      variant='main'
                      placeholder='eg. Jane Doe'
                      fontSize='xs'
                      isRequired
                      onChange={handleDirector2}
                    />
                  </FormControl>
                </Stack>
                <Stack direction='row' spacing={{ sm: '24px', lg: '30px' }}>
                  <FormControl>
                    <FormLabel fontWeight='semibold' fontSize='xs' mb='10px'>
                      VAT Number
                    </FormLabel>
                    <Input
                      variant='main'
                      placeholder='eg. GB123456789'
                      fontSize='xs'
                      isRequired
                      onChange={handleVATNumber}
                    />
                  </FormControl>
                  <FormControl></FormControl>
                </Stack>
                <Stack
                  direction={{ sm: 'column', lg: 'row' }}
                  spacing={{ sm: '24px', lg: '30px' }}
                >
                  <FormControl>
                    <FormLabel fontWeight='semibold' fontSize='xs' mb='10px'>
                      Business Address Line 1
                    </FormLabel>
                      <Input
                        variant='main'
                        placeholder='eg. 721 Business Park'
                        fontSize='xs'
                        isRequired
                        onChange={handleBusinessLine1}
                      />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight='semibold' fontSize='xs' mb='10px'>
                      Business Address Line 2
                    </FormLabel>
                      <Input
                        variant='main'
                        placeholder='eg. Address Line 2'
                        fontSize='xs'
                        isRequired
                        onChange={handleBusinessLine2}
                      />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight='semibold' fontSize='xs' mb='10px'>
                      Business Address City
                    </FormLabel>
                      <Input
                        variant='main'
                        placeholder='eg. Leeds'
                        fontSize='xs'
                        isRequired
                        onChange={handleBusinessCity}
                      />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight='semibold' fontSize='xs' mb='10px'>
                      Business Address Postcode
                    </FormLabel>
                      <Input
                        variant='main'
                        placeholder='eg. LS15 1AQ'
                        fontSize='xs'
                        isRequired
                        onChange={handleBusinessPostcode}
                      />
                  </FormControl>
                </Stack>
                {basicInfoIsLoading ? (
                  <Button colorScheme='blue' isLoading loadingText='Submitting...' onClick={handleBasicInfoSubmit}>Submit</Button>
                ):(
                  <Button colorScheme='blue' onClick={handleBasicInfoSubmit}>Submit</Button>
                )}
                
              </Stack>
            </CardBody>
          </Element>
          )}
          
        </Card>
        <Card
          w={{ sm: '100%', lg: '70%' }}
          alignSelf='flex-end'
          justifySelf='flex-end'
        >
          <Element id='discovery-call' name='discovery-call'>
          <CardHeader mb='40px'>
                <Text color={textColor} fontSize='lg' fontWeight='semibold'>
                  Book Discovery Call
                </Text>
              </CardHeader>
            {onboardingPerms.discovery ? (
              <><CardBody isDisabled>
                  <Stack direction='column' spacing='20px' w='100%'>
                  <InlineWidget 
                   url="https://calendly.com/vincentanthony/30min"
                   styles={{
                    height: '1100px'
                  }}
                  prefill={{
                    email: 'test@test.com',
                    firstName: 'Jon',
                    lastName: 'Snow',
                    name: 'Jon Snow',
                    }} />
                  </Stack>
                </CardBody></>
            ):(
              <Alert
              status='info'
              variant='subtle'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              textAlign='center'
              height='200px'
            > 
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              We're not ready for you yet!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              We'll send you an email when this step is ready to be completed
            </AlertDescription>
            </Alert>
            )}
            
          </Element>
        </Card>
        <Card
          w={{ sm: '100%', lg: '70%' }}
          alignSelf='flex-end'
          justifySelf='flex-end'
        >
          <Element id='non-disclosure-agreement' name='non-disclosure-agreement'>
            <CardHeader mb='32px'>
              <Flex justify='space-between' w='100%'>
                <Text fontSize='lg' fontWeight='bold' color={textColor}>
                  Sign Non-Disclosure Agreement
                </Text>
              </Flex>
            </CardHeader>
            {onboardingPerms.discovery ? (
              <CardBody>
              <Stack direction='column' spacing='22px' w='100%'>
              <Flex
                p='1rem'
                bg={colorMode === 'dark' ? 'navy.900' : 'transparent'}
                borderRadius='15px'
                width='100%'
                border='1px solid'
                borderColor={borderColor}
                align='center'
                mb={{ sm: '24px', md: '0px' }}
                me={{ sm: '0px', md: '24px' }}
              >
                <Button p='0px' h='16px' variant='no-effects' onClick={onOpen}>
                <IconBox me='10px' w='25px' h='22px'>
                <Icon
                    as={FaFilePdf}
                    color={colorMode === 'dark' && 'white'}
                  />
                  
                
                </IconBox>
                <Text color='gray.400' fontSize='md' fontWeight='semibold'>
                  Propertynest Non-Disclosure Agreement
                </Text>
                </Button>
                <Spacer />
                  <Modal onClose={onClose} isOpen={isOpen}>
                <ModalOverlay />
                  <ModalContent minW='800px'>
                    <ModalHeader>Propertynest Non-Disclosure Agreement</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer fileUrl="/dummy.pdf" 
                  plugins={[
                    // Register plugins
                    defaultLayoutPluginInstance
                ]}
                height='20px'
                />
                  </Worker>
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                    <Button onClick={onClose}>Sign Now</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              </Flex>
              <Button colorScheme='blue'>Sign Now</Button>
              </Stack>
            </CardBody>
            ):(
              <Alert
              status='info'
              variant='subtle'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              textAlign='center'
              height='200px'
            > 
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              We're not ready for you yet!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              We'll send you an email when this step is ready to be completed
            </AlertDescription>
            </Alert>
            )}
            
          </Element>
        </Card>
        <Card
          w={{ sm: '100%', lg: '70%' }}
          alignSelf='flex-end'
          justifySelf='flex-end'
        >
          <Element to='discovery-session' name='discovery-session'>
            <CardHeader mb='40px'>
              <Flex direction='column'>
                <Text
                  color={textColor}
                  fontSize='lg'
                  fontWeight='semibold'
                  mb='4px'
                >
                  Discovery Session
                </Text>
                <Text color='gray.400' fontWeight='normal' fontSize='sm'>
                  Here you can setup and manage your integration settings
                </Text>
              </Flex>
            </CardHeader>
            {onboardingPerms.discovery_session ? (
              <CardBody>
              <Stack direction='column' spacing='22px' w='100%'>
                <Flex direction='column'>
                  <Flex justify='space-between' mb='16px'>
                    <Flex align='center'>
                      <Icon as={SlackLogo} w='34px' h='34px' me='14px' />
                      <Flex direction='column'>
                        <Text fontSize='md' color={textColor} fontWeight='bold'>
                          Slack
                        </Text>
                        <Flex align='center'>
                          <Text
                            color='gray.400'
                            fontWeight='normal'
                            fontSize='xs'
                            me='6px'
                          >
                            Show Less
                          </Text>
                          <Icon as={IoIosArrowUp} color='gray.400' />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex align='center'>
                      <Text
                        color='gray.500'
                        fontWeight='normal'
                        me='17px'
                        fontSize='xs'
                      >
                        Enabled
                      </Text>
                      <Switch colorScheme='blue' defaultIsChecked />
                    </Flex>
                  </Flex>
                  <Stack direction='column' w='100%' spacing='18px' ps='50px'>
                    <Text color='gray.400' fontWeight='normal' fontSize='sm'>
                      You haven't added your Slack yet or you aren't authorized.
                      Please add our Slack Bot to your account by clicking on
                      here. When you've added the bot, send your verification
                      code that you have received.
                    </Text>
                    <Flex
                      direction={{ sm: 'column', md: 'row' }}
                      justify='space-between'
                      bg={bgVerificationCard}
                      borderRadius='12px'
                      w='100%'
                      align='center'
                      p='10px 12px'
                    >
                      <Text
                        color={secondaryColor}
                        fontWeight='bold'
                        fontSize='sm'
                        mb={{ sm: '8px', lg: null }}
                      >
                        Verification Code
                      </Text>
                      <Tooltip
                        hasArrow
                        label='Copy!'
                        bg='blue.500'
                        placement='top'
                      >
                        <Input
                          variant='main'
                          value='1172913'
                          maxW='200px'
                          color='gray.400'
                          readOnly
                        />
                      </Tooltip>
                    </Flex>
                    <Flex
                      direction={{ sm: 'column', md: 'row' }}
                      justify='space-between'
                      bg={bgVerificationCard}
                      borderRadius='12px'
                      w='100%'
                      align='center'
                      p='10px 12px'
                    >
                      <Text
                        color={secondaryColor}
                        fontWeight='bold'
                        fontSize='sm'
                      >
                        Connected Account
                      </Text>
                      <Flex
                        align='center'
                        direction={{ sm: 'column', md: 'row' }}
                        justify={{ sm: 'center', lg: null }}
                      >
                        <Text
                          color={secondaryColor}
                          fontSize='sm'
                          me='18px'
                          my={{ sm: '6px', lg: null }}
                        >
                          hello@microsoft-ui.com
                        </Text>
                        <Button
                          variant='danger'
                          w='100px'
                          h='35px'
                          fontSize='10px'
                        >
                          DELETE
                        </Button>
                      </Flex>
                    </Flex>
                  </Stack>
                </Flex>
                <HSeparator />
                <Flex justify='space-between'>
                  <Flex align='center'>
                    <Icon as={SpotifyLogo} w='34px' h='34px' me='14px' />
                    <Flex direction='column'>
                      <Text fontSize='md' color={textColor} fontWeight='bold'>
                        Spotify
                      </Text>
                      <Text color='gray.400' fontWeight='normal' fontSize='xs'>
                        Music
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex align='center'>
                    <Text
                      color='gray.500'
                      fontWeight='normal'
                      me='17px'
                      fontSize='xs'
                    >
                      Enabled
                    </Text>
                    <Switch colorScheme='blue' defaultIsChecked />
                  </Flex>
                </Flex>
                <HSeparator />
                <Flex justify='space-between'>
                  <Flex align='center'>
                    <Icon as={AtlassianLogo} w='34px' h='34px' me='14px' />
                    <Flex direction='column'>
                      <Text fontSize='md' color={textColor} fontWeight='bold'>
                        Atlassian
                      </Text>
                      <Text color='gray.400' fontWeight='normal' fontSize='xs'>
                        Payment Vendor
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex align='center'>
                    <Text
                      color='gray.500'
                      fontWeight='normal'
                      me='17px'
                      fontSize='xs'
                    >
                      Enabled
                    </Text>
                    <Switch colorScheme='blue' defaultIsChecked />
                  </Flex>
                </Flex>
                <HSeparator />
                <Flex justify='space-between'>
                  <Flex align='center'>
                    <Icon as={InvisionLogo} w='34px' h='34px' me='14px' />
                    <Flex direction='column'>
                      <Text fontSize='md' color={textColor} fontWeight='bold'>
                        Invision
                      </Text>
                      <Text color='gray.400' fontWeight='normal' fontSize='xs'>
                        Design Better
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex align='center'>
                    <Switch colorScheme='blue' />
                  </Flex>
                </Flex>
              </Stack>
            </CardBody>
            ):(
              <Alert
              status='info'
              variant='subtle'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              textAlign='center'
              height='200px'
            > 
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              We're not ready for you yet!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              We'll send you an email when this step is ready to be completed
            </AlertDescription>
            </Alert>
            )}
            
          </Element>
        </Card>
        <Card
          pb='0px'
          w={{ sm: '100%', lg: '70%' }}
          alignSelf='flex-end'
          justifySelf='flex-end'
        >
          <Element to='application-form' name='application-form'>
            <CardHeader mb='40px'>
              <Flex direction='column'>
                <Text
                  color={textColor}
                  fontSize='lg'
                  fontWeight='semibold'
                  mb='4px'
                >
                  Application Form
                </Text>
                <Text color='gray.400' fontWeight='normal' fontSize='sm'>
                  Choose how you receive notifications. These notification
                  settings apply to the things you’re watching.
                </Text>
              </Flex>
            </CardHeader>
            {onboardingPerms.business_planning ? (
              <CardBody overflowX={{ sm: 'scroll', lg: 'hidden' }}>
              <Table>
                <Thead>
                  <Tr>
                    <Th
                      color='gray.400'
                      fontSize='md'
                      fontWeight='normal'
                      ps='0px'
                      textTransform='capitalise'
                      borderColor={borderTableColor}
                    >
                      Activity
                    </Th>
                    <Th
                      color='gray.400'
                      fontSize='md'
                      fontWeight='normal'
                      textTransform='capitalise'
                      borderColor={borderTableColor}
                    >
                      Email
                    </Th>
                    <Th
                      color='gray.400'
                      fontSize='md'
                      fontWeight='normal'
                      textTransform='capitalise'
                      borderColor={borderTableColor}
                    >
                      Push
                    </Th>
                    <Th
                      color='gray.400'
                      fontSize='md'
                      fontWeight='normal'
                      textTransform='capitalise'
                      borderColor={borderTableColor}
                    >
                      SMS
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td
                      borderColor={borderTableColor}
                      ps='0px'
                      minW={{ sm: '300px' }}
                    >
                      <Flex direction='column'>
                        <Text
                          color={textColor}
                          fontWeight='normal'
                          fontSize='sm'
                          mb='4px'
                        >
                          Mentions
                        </Text>
                        <Text
                          color='gray.400'
                          fontSize='xs'
                          fontWeight='normal'
                        >
                          Notify when another user mentions you in a comment
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderTableColor}>
                      <Switch colorScheme='blue' />
                    </Td>
                    <Td borderColor={borderTableColor}>
                      <Switch defaultIsChecked colorScheme='blue' />
                    </Td>
                    <Td borderColor={borderTableColor}>
                      <Switch defaultIsChecked colorScheme='blue' />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      borderColor={borderTableColor}
                      ps='0px'
                      minW={{ sm: '300px' }}
                    >
                      <Flex direction='column'>
                        <Text
                          color={textColor}
                          fontWeight='normal'
                          fontSize='sm'
                          mb='4px'
                        >
                          Comments
                        </Text>
                        <Text
                          color='gray.400'
                          fontSize='xs'
                          fontWeight='normal'
                        >
                          Notify when another user comments your item.
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderTableColor}>
                      <Switch defaultIsChecked colorScheme='blue' />
                    </Td>
                    <Td borderColor={borderTableColor}>
                      <Switch defaultIsChecked colorScheme='blue' />
                    </Td>
                    <Td borderColor={borderTableColor}>
                      <Switch colorScheme='blue' />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      borderColor={borderTableColor}
                      ps='0px'
                      minW={{ sm: '300px' }}
                    >
                      <Flex direction='column'>
                        <Text
                          color={textColor}
                          fontWeight='normal'
                          fontSize='sm'
                          mb='4px'
                        >
                          Follows
                        </Text>
                        <Text
                          color='gray.400'
                          fontSize='xs'
                          fontWeight='normal'
                        >
                          Notify when another user follows you.
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderTableColor}>
                      <Switch defaultIsChecked colorScheme='blue' />
                    </Td>
                    <Td borderColor={borderTableColor}>
                      <Switch colorScheme='blue' />
                    </Td>
                    <Td borderColor={borderTableColor}>
                      <Switch defaultIsChecked colorScheme='blue' />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      borderColor={borderTableColor}
                      border='none'
                      ps='0px'
                      py='24px'
                      minW={{ sm: '300px' }}
                    >
                      <Flex direction='column'>
                        <Text
                          color={textColor}
                          fontWeight='normal'
                          fontSize='xs'
                          mb='4px'
                        >
                          Log in from a new device
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderTableColor} border='none'>
                      <Switch colorScheme='blue' />
                    </Td>
                    <Td borderColor={borderTableColor} border='none'>
                      <Switch defaultIsChecked colorScheme='blue' />
                    </Td>
                    <Td borderColor={borderTableColor} border='none'>
                      <Switch colorScheme='blue' />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </CardBody>
            ):(
              <Alert
              status='info'
              variant='subtle'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              textAlign='center'
              height='200px'
            > 
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              We're not ready for you yet!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              We'll send you an email when this step is ready to be completed
            </AlertDescription>
            </Alert>
            )}
            
          </Element>
        </Card>
        <Card
          w={{ sm: '100%', lg: '70%' }}
          alignSelf='flex-end'
          justifySelf='flex-end'
        >
          <Element id='franchisee-playbook' name='franchisee-playbook'>
            <CardHeader mb='40px'>
              <Flex direction='column'>
                <Text
                  color={textColor}
                  fontSize='lg'
                  fontWeight='semibold'
                  mb='4px'
                >
                  Franchisee Playbook
                </Text>
                <Text color='gray.400' fontWeight='normal' fontSize='sm'>
                  This is a list of devices that have logged into your account.
                  Remove those that you do not recognize.
                </Text>
              </Flex>
            </CardHeader>
            {onboardingPerms.business_planning ? (
              <CardBody>
              <Stack direction='column' spacing='18px' w='100%'>
                <Flex
                  direction={{ sm: 'column', md: 'row' }}
                  justify='space-between'
                  w='100%'
                >
                  <Flex align='center'>
                    <Icon
                      as={RiComputerLine}
                      me='30px'
                      w='28px'
                      h='28px'
                      color='gray.400'
                    />
                    <Flex direction='column' mb={{ sm: '8px', lg: null }}>
                      <Text color={textColor} fontWeight='normal' fontSize='sm'>
                        Bucharest 68.133.163.201
                      </Text>
                      <Text color='gray.400' fontSize='xs' fontWeight='normal'>
                        Your current session
                      </Text>
                    </Flex>
                  </Flex>
                  <Stack
                    direction='row'
                    spacing='24px'
                    align='center'
                    alignSelf={{ sm: 'flex-end', lg: null }}
                    minW={{ lg: '280px' }}
                  >
                    <Badge
                      bg={colorMode === 'light' ? 'green.100' : 'green.400'}
                      color={colorMode === 'light' ? 'green.400' : 'white'}
                      borderRadius='12px'
                      p='12px'
                    >
                      ACTIVE
                    </Badge>
                    <Text color='gray.400' fontSize='md' fontWeight='normal'>
                      EU
                    </Text>
                    <Button variant='no-effects' color='blue.500'>
                      <Flex
                        align='center'
                        color='blue.500'
                        w='100%'
                        fontSize='sm'
                      >
                        <Text
                          me='6px'
                          transition='all .3s ease'
                          _hover={{ me: '14px' }}
                          fontSize='sm'
                        >
                          See more
                        </Text>
                        <Icon
                          as={BsArrowRight}
                          w='20px'
                          h='20px'
                          transition='all .3s ease'
                          _hover={{ transform: 'translateX(50%)' }}
                        />
                      </Flex>
                    </Button>
                  </Stack>
                </Flex>
                <HSeparator />
                <Flex
                  direction={{ sm: 'column', md: 'row' }}
                  justify='space-between'
                  w='100%'
                >
                  <Flex align='center'>
                    <Icon
                      as={RiComputerLine}
                      me='30px'
                      w='28px'
                      h='28px'
                      color='gray.400'
                    />
                    <Flex direction='column'>
                      <Text color={textColor} fontWeight='normal' fontSize='sm'>
                        Chrome on macOS
                      </Text>
                    </Flex>
                  </Flex>
                  <Stack
                    direction='row'
                    spacing='24px'
                    align='center'
                    alignSelf={{ sm: 'flex-end', lg: null }}
                    minW={{ lg: '185px' }}
                  >
                    <Text color='gray.400' fontSize='md' fontWeight='normal'>
                      US
                    </Text>
                    <Button variant='no-effects' color='blue.500'>
                      <Flex
                        align='center'
                        color='blue.500'
                        w='100%'
                        fontSize='sm'
                      >
                        <Text
                          me='6px'
                          transition='all .3s ease'
                          _hover={{ me: '14px' }}
                          fontSize='sm'
                        >
                          See more
                        </Text>
                        <Icon
                          as={BsArrowRight}
                          w='20px'
                          h='20px'
                          transition='all .3s ease'
                          _hover={{ transform: 'translateX(50%)' }}
                        />
                      </Flex>
                    </Button>
                  </Stack>
                </Flex>
                <HSeparator />
                <Flex
                  direction={{ sm: 'column', md: 'row' }}
                  justify='space-between'
                  w='100%'
                >
                  <Flex align='center'>
                    <Icon
                      as={GiSmartphone}
                      me='30px'
                      w='28px'
                      h='28px'
                      color='gray.400'
                    />
                    <Flex direction='column'>
                      <Text color={textColor} fontWeight='normal' fontSize='sm'>
                        Safari on iPhone
                      </Text>
                    </Flex>
                  </Flex>
                  <Stack
                    direction='row'
                    spacing='24px'
                    align='center'
                    alignSelf={{ sm: 'flex-end', lg: null }}
                    justifySelf='flex-end'
                    minW={{ lg: '185px' }}
                  >
                    <Text color='gray.400' fontSize='md' fontWeight='normal'>
                      US
                    </Text>
                    <Button variant='no-effects' color='blue.500'>
                      <Flex
                        align='center'
                        color='blue.500'
                        w='100%'
                        fontSize='sm'
                      >
                        <Text
                          me='6px'
                          transition='all .3s ease'
                          _hover={{ me: '14px' }}
                          fontSize='sm'
                        >
                          See more
                        </Text>
                        <Icon
                          as={BsArrowRight}
                          w='20px'
                          h='20px'
                          transition='all .3s ease'
                          _hover={{ transform: 'translateX(50%)' }}
                        />
                      </Flex>
                    </Button>
                  </Stack>
                </Flex>
              </Stack>
            </CardBody>
            ):(
              <Alert
              status='info'
              variant='subtle'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              textAlign='center'
              height='200px'
            > 
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              We're not ready for you yet!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              We'll send you an email when this step is ready to be completed
            </AlertDescription>
            </Alert>
            )}
            
          </Element>
        </Card>
        <Card
          w={{ sm: '100%', lg: '70%' }}
          alignSelf='flex-end'
          justifySelf='flex-end'
        >
          <Element id='head-office-meeting' name='head-office-meeting'>
            <CardHeader mb='40px'>
              <Flex direction='column'>
                <Text
                  color={textColor}
                  fontSize='lg'
                  fontWeight='bold'
                  mb='4px'
                >
                  Head Office Meeting
                </Text>
                <Text color='gray.400' fontWeight='normal' fontSize='sm'>
                  Once you delete your account, there is no going back. Please
                  be certain.
                </Text>
              </Flex>
            </CardHeader>
            {onboardingPerms.final_meeting ? (
              <CardBody>
              <Flex
                direction={{ sm: 'column', md: 'row' }}
                justify='space-between'
                align='start'
                w='100%'
              >
                <Flex align='center' mb={{ sm: '16px', lg: null }}>
                  <Switch colorScheme='blue' me='22px' />
                  <Flex direction='column'>
                    <Text
                      fontSize='sm'
                      color={textColor}
                      mb='4px'
                      fontWeight='semibold'
                    >
                      Confirm
                    </Text>
                    <Text color='gray.400' fontWeight='normal' fontSize='xs'>
                      I want to delete my account.
                    </Text>
                  </Flex>
                </Flex>
                <Flex align='center'>
                  <Button
                    variant='outline'
                    colorScheme='black'
                    w='120px'
                    h='35px'
                    fontSize='10px'
                    me='14px'
                  >
                    DEACTIVATE
                  </Button>
                  <Button variant='danger' w='150px' h='35px' fontSize='10px'>
                    DELETE ACCOUNT
                  </Button>
                </Flex>
              </Flex>
            </CardBody>
            ):(
              <Alert
              status='info'
              variant='subtle'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              textAlign='center'
              height='200px'
            > 
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              We're not ready for you yet!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              We'll send you an email when this step is ready to be completed
            </AlertDescription>
            </Alert>
            )}
            
          </Element>
          </Card>
          <Card
          w={{ sm: '100%', lg: '70%' }}
          alignSelf='flex-end'
          justifySelf='flex-end'
        >
          <Element id='territory-reservation-agreement' name='territory-reservation-agreement'>
            <CardHeader mb='40px'>
              <Flex direction='column'>
                <Text
                  color={textColor}
                  fontSize='lg'
                  fontWeight='bold'
                  mb='4px'
                >
                  Territory Reservation Agreement
                </Text>
                <Text color='gray.400' fontWeight='normal' fontSize='sm'>
                  Once you delete your account, there is no going back. Please
                  be certain.
                </Text>
              </Flex>
            </CardHeader>
            {onboardingPerms.final_meeting ? (
              <CardBody>
              <Flex
                direction={{ sm: 'column', md: 'row' }}
                justify='space-between'
                align='start'
                w='100%'
              >
                <Flex align='center' mb={{ sm: '16px', lg: null }}>
                  <Switch colorScheme='blue' me='22px' />
                  <Flex direction='column'>
                    <Text
                      fontSize='sm'
                      color={textColor}
                      mb='4px'
                      fontWeight='semibold'
                    >
                      Confirm
                    </Text>
                    <Text color='gray.400' fontWeight='normal' fontSize='xs'>
                      I want to delete my account.
                    </Text>
                  </Flex>
                </Flex>
                <Flex align='center'>
                  <Button
                    variant='outline'
                    colorScheme='black'
                    w='120px'
                    h='35px'
                    fontSize='10px'
                    me='14px'
                  >
                    DEACTIVATE
                  </Button>
                  <Button variant='danger' w='150px' h='35px' fontSize='10px'>
                    DELETE ACCOUNT
                  </Button>
                </Flex>
              </Flex>
            </CardBody>
            ):(
              <Alert
              status='info'
              variant='subtle'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              textAlign='center'
              height='200px'
            > 
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              We're not ready for you yet!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              We'll send you an email when this step is ready to be completed
            </AlertDescription>
            </Alert>
            )}
            
          </Element>
          </Card>
          <Card
          w={{ sm: '100%', lg: '70%' }}
          alignSelf='flex-end'
          justifySelf='flex-end'
        >
          <Element id='franchise-offer' name='franchise-offer'>
            <CardHeader mb='40px'>
              <Flex direction='column'>
                <Text
                  color={textColor}
                  fontSize='lg'
                  fontWeight='bold'
                  mb='4px'
                >
                  Franchise Offer
                </Text>
                <Text color='gray.400' fontWeight='normal' fontSize='sm'>
                  Once you delete your account, there is no going back. Please
                  be certain.
                </Text>
              </Flex>
            </CardHeader>
            {onboardingPerms.final_meeting ? (
              <CardBody>
              <Flex
                direction={{ sm: 'column', md: 'row' }}
                justify='space-between'
                align='start'
                w='100%'
              >
                <Flex align='center' mb={{ sm: '16px', lg: null }}>
                  <Switch colorScheme='blue' me='22px' />
                  <Flex direction='column'>
                    <Text
                      fontSize='sm'
                      color={textColor}
                      mb='4px'
                      fontWeight='semibold'
                    >
                      Confirm
                    </Text>
                    <Text color='gray.400' fontWeight='normal' fontSize='xs'>
                      I want to delete my account.
                    </Text>
                  </Flex>
                </Flex>
                <Flex align='center'>
                  <Button
                    variant='outline'
                    colorScheme='black'
                    w='120px'
                    h='35px'
                    fontSize='10px'
                    me='14px'
                  >
                    DEACTIVATE
                  </Button>
                  <Button variant='danger' w='150px' h='35px' fontSize='10px'>
                    DELETE ACCOUNT
                  </Button>
                </Flex>
              </Flex>
            </CardBody>
            ):(
              <Alert
              status='info'
              variant='subtle'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              textAlign='center'
              height='200px'
            > 
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              We're not ready for you yet!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              We'll send you an email when this step is ready to be completed
            </AlertDescription>
            </Alert>
            )}
            
          </Element>
        </Card>
      </Stack>
      ):null}
      
    </Flex>
  );
}

export default Settings;
