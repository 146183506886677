/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from 'react';
import { useState } from 'react';

// Chakra imports
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  LightMode,
  useColorModeValue,
  Spinner,
} from '@chakra-ui/react';
// Assets
import { IoIosArrowDown } from 'react-icons/io';
// Custom components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import SearchTable2 from 'components/Tables/SearchTable2';
import { userColumns } from 'variables/columnsData';
import tableData2 from 'variables/tableData2.json';
import ApiService from 'hooks/useApi';
import BasicUsage from 'components/Modals/NewUserModal';


function OrderList() {
  const [tabledata, setTableData] = useState([])
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    ApiService.getusers().then(data => {
      setTableData(data)
      setIsLoaded(true)
    })
  }, [])
  return (
    <Flex direction='column' pt={{ sm: '125px', md: '75px' }}>
      <Flex
        direction={{ sm: 'column', md: 'row' }}
        justify='space-between'
        align='center'
        w='100%'
        mb='24px'
      ><BasicUsage />
        <Stack
          direction='row'
          spacing='10px'
          alignSelf={{ sm: 'flex-start', lg: 'auto' }}
        >
          <LightMode>
            <Button
              variant='light'
              bg={useColorModeValue('white', 'blue.500')}
              color={useColorModeValue('gray.700', 'white')}
              _hover={useColorModeValue({}, { bg: 'blue.600' })}
              _active={useColorModeValue({}, { bg: 'blue.400' })}
              _focus={useColorModeValue({}, { bg: 'blue.500' })}
              w='125px'
              h='35px'
              fontSize='xs'
              fontWeight='bold'
            >
              EXPORT CSV
            </Button>
          </LightMode>
        </Stack>
      </Flex>
      <Card px='0px'>
        <CardBody>
          {isLoaded ? (
            <SearchTable2 tableData={tabledata} columnsData={userColumns} />
          ):(
            <Spinner
  thickness='4px'
  speed='0.65s'
  emptyColor='gray.200'
  color='blue.500'
  size='xl'
/>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default OrderList;
