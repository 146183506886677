import { useDisclosure } from "@chakra-ui/react"
import { 
    Button, 
    Modal, 
    ModalOverlay, 
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Text,
    useColorModeValue,
    FormControl,
    FormLabel, 
    Input,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Stack,
    Flex,
    Select,
    Spinner,
    Center
} from "@chakra-ui/react"
import { useState, useCallback } from "react"
import ApiService from 'hooks/useApi'
import { useDropzone } from 'react-dropzone';

export default function BasicUsage(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [inviteButton, setInviteButton] = useState(false)
    const [emailAddress, setEmailAddress] = useState("")
    const [errMessage, setErrMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [selectedImages, setSelectedImages] = useState([])
    const textColor = useColorModeValue('gray.700', 'white');
    const bgPrevButton = useColorModeValue('gray.100', 'gray.100');
    const iconColor = useColorModeValue('gray.300', 'gray.700');
    const [selectedFeedType, setSelectedFeedType] = useState("0")
    const [addIsDisabled, setAddIsDisabled] = useState(true)
    const [tenNinetyUrl, setTenNinetyUrl] = useState("")
    const [isTesting, setIsTesting] = useState(false)
    const [isTested, setIsTested] = useState("")
    const [testFinished, setTestFinished] = useState(false)
    
    function handle10ninetyURL(e){
      e.preventDefault
      setTenNinetyUrl(e.target.value)
      if(e.target.value.length > 0){
        setAddIsDisabled(false)
      }else {
        setAddIsDisabled(true)
      }
    }
    
    function addFeedToBranch(){
      setIsTesting(true)
      setAddIsDisabled(true)
      if(tenNinetyUrl != isTested){
        if(selectedFeedType == '10ninety'){
          ApiService.testBranchFeed('10ninety', tenNinetyUrl, props.branch_id).then(data =>{
            console.log(data)
            if(data.status == true){
              setIsTested(tenNinetyUrl)
              setSuccessMessage(data.message)
              setTestFinished(true)
              setAddIsDisabled(false)
            }
          })
        }
      }else{
        ApiService.addBranchFeed(selectedFeedType, tenNinetyUrl, props.branch_id).then(data => {
          setSuccessMessage(data.message)
          onClose()
          setSuccessMessage("")
          setIsTested("")
          setTestFinished(false)
          setIsTesting(false)
          setSelectedFeedType('0')
        })
        console.log('adding feed')
      }
      
    }

    function handleChange(e){
      e.preventDefault
      setSelectedFeedType(e.target.value)
    }

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
      console.log('ting')
      console.log(acceptedFiles)
      acceptedFiles.forEach((file) => {
        console.log(file)
        ApiService.uploadBranchImage(file, 585).then(data => {
          console.log(data)
          window.location.reload(false)
        })
      })
    }, [])
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop, maxFiles: 1, accept: "image/*" });

    function handleEmailChange(e){
        setEmailAddress(e.target.value)
      }

    function handleSubmit(e){
        e.preventDefault()
        setInviteButton(true)
        setErrMessage("")
        setSuccessMessage("")
        props.setBranchHierarchy('hi')
    }
    return (
      <>
        <Button
          variant='no-effects'
          bg={useColorModeValue('gray.700', 'white')}
          w='130px'
          h='35px'
          color={useColorModeValue('white', 'gray.700')}
          fontSize='xs'
          fontWeight='bold'
          alignSelf={{ sm: 'flex-start', lg: null }}
          mb={{ sm: '12px', md: '0px' }}
          onClick={onOpen}
        >
          Add feed to branch
        </Button>
  
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add a new feed source to this branch</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {testFinished !=true ? (
                <Flex
                    align='center'
                    justify='center'
                    bg={useColorModeValue('none', 'navy.900')}
                    border={useColorModeValue('1px #E2E8F0', 'none')}
                    w='100%'
                    minH='130px'
                  >
                    {isTesting ? (
                      <Stack direction='column' spacing='24px' width='100%'>
                        <Center>
                      <Spinner size='xl'/></Center>
                      <Center><Text>Testing Feed URL</Text>
                      </Center>
                     
                      </Stack>
                    ):(
                    <Stack direction='column' spacing='24px' width='100%'>
                    <FormControl>
                        <FormLabel
                          color={textColor}
                          fontSize='xs'
                          fontWeight='bold'
                        >
                          Feed Type
                        </FormLabel>
                        <Select
                          variant='main'
                          fontSize='xs'
                          color='gray.400'
                          name="feedtype"
                          onChange={handleChange}
                        >
                        <option value='0'></option>
                        <option value='alto'>Alto API</option>
                        <option value='10ninety'>10ninety</option>
                        </Select>
                        </FormControl>
                        {selectedFeedType == '10ninety' ? (
                          <FormControl>
                          <FormLabel
                          color={textColor}
                          fontSize='xs'
                          fontWeight='bold'
                        >
                          10ninety URL
                        </FormLabel>
                        <Input onChange={handle10ninetyURL}></Input>
                        </FormControl>
                        ):null}
                        </Stack>
                    )}
                    
                  </Flex>
              ):null}
            {errMessage != "" ?(
                <Stack spacing={3}>
                 <Alert borderRadius='8px' status='error' variant='subtle'>
              <AlertIcon />
              <AlertTitle mr='12px'>Error</AlertTitle>
              <AlertDescription>{errMessage}</AlertDescription>
            </Alert>
               </Stack>
            ):null}
            {successMessage != "" ?(
                <Alert borderRadius='8px' status='success' variant='subtle'>
                <AlertIcon />
                <AlertTitle mr='12px'>Success</AlertTitle>
                <AlertDescription>{successMessage}</AlertDescription>
              </Alert>
            ):null}
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='red' 
              mr={3}
              onClick={onClose}
              >
                Close
              </Button>
              <Button colorScheme='blue' 
              mr={3}
              disabled={addIsDisabled}
              onClick={addFeedToBranch}
              >
                Add feed to branch
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }