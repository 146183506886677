import axios from "axios";

//const BASE_URL = "http://localhost:8000/webapi/"
const BASE_URL = "https://api.propertynest.co.uk/webapi/"

class AuthService {
    login(username, password){
        return axios.post(BASE_URL+"login", {
            "username": username,
            "password": password
        }).then(response => {
            if(response.data.access_token){
                localStorage.setItem("user", JSON.stringify(response.data))
            }
            return response.data
        })
    }
}

export default new AuthService();