import { useDisclosure } from "@chakra-ui/react"
import { 
    Button, 
    Modal, 
    ModalOverlay, 
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Text,
    useColorModeValue,
    FormControl,
    FormLabel, 
    Input,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Stack,
    Flex,
} from "@chakra-ui/react"
import { useState, useCallback } from "react"
import ApiService from 'hooks/useApi'
import { useDropzone } from 'react-dropzone';

export default function BasicUsage(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [inviteButton, setInviteButton] = useState(false)
    const [emailAddress, setEmailAddress] = useState("")
    const [errMessage, setErrMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [selectedImages, setSelectedImages] = useState([])


    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
      console.log('ting')
      console.log(acceptedFiles)
      acceptedFiles.forEach((file) => {
        console.log(file)
        ApiService.uploadBranchImage(file, props.branch_id).then(data => {
          console.log(data)
          window.location.reload(false)
        })
      })
    }, [])
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop, maxFiles: 1, accept: "image/*" });

    function handleEmailChange(e){
        setEmailAddress(e.target.value)
      }

    function handleSubmit(e){
        e.preventDefault()
        setInviteButton(true)
        setErrMessage("")
        setSuccessMessage("")
        props.setBranchHierarchy('hi')
    }
    return (
      <>
        <Button
          variant='no-effects'
          bg={useColorModeValue('gray.700', 'white')}
          w='125px'
          h='35px'
          color={useColorModeValue('white', 'gray.700')}
          fontSize='xs'
          fontWeight='bold'
          alignSelf={{ sm: 'flex-start', lg: null }}
          mb={{ sm: '12px', md: '0px' }}
          onClick={onOpen}
        >
          Add
        </Button>
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Change Branch Image</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Flex
                    align='center'
                    justify='center'
                    bg={useColorModeValue('none', 'navy.900')}
                    border={useColorModeValue('1px dashed #E2E8F0', 'none')}
                    w='100%'
                    minH='130px'
                    cursor='pointer'
                    {...getRootProps({ className: 'dropzone' })}
                  >
                    <Input variant='main' {...getInputProps()} />
                    <Button variant='no-effects'>
                      <Text color='gray.400' fontWeight='normal'>
                        Click or drop file here to upload
                      </Text>
                    </Button>
                  </Flex>
            {errMessage != "" ?(
                <Stack spacing={3}>
                 <Alert borderRadius='8px' status='error' variant='subtle'>
              <AlertIcon />
              <AlertTitle mr='12px'>Error</AlertTitle>
              <AlertDescription>{errMessage}</AlertDescription>
            </Alert>
               </Stack>
            ):null}
            {successMessage != "" ?(
                <Alert borderRadius='8px' status='success' variant='subtle'>
                <AlertIcon />
                <AlertTitle mr='12px'>Success</AlertTitle>
                <AlertDescription>User invited successfully</AlertDescription>
              </Alert>
            ):null}
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='red' mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }