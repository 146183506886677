import { HamburgerIcon } from '@chakra-ui/icons';
// chakra imports
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	Flex,
	HStack,
	Icon,
	List,
	ListItem,
	Stack,
	Text,
	useColorModeValue,
	useDisclosure
} from '@chakra-ui/react';
import IconBox from 'components/Icons/IconBox';
import {
	renderThumbDark,
	renderThumbLight,
	renderTrack,
	renderTrackRTL,
	renderView,
	renderViewRTL
} from 'components/Scrollbar/Scrollbar';
import { HSeparator } from 'components/Separator/Separator';
import { SidebarContext } from 'contexts/SidebarContext';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FaCircle } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';

import {
    CartIcon,
    DocumentIcon,
    HomeIcon,
    PersonIcon,
    StatsIcon,
  } from "components/Icons/Icons";

export default function AdminDropdown(props){
    const key="Home"
    let variantChange = '0.2s linear';
    const activeRoute = (routeName) => {
        console.log(location.pathname)
		return location.pathname.includes(routeName);
	};
    let activeBg = 'blue.500';
	let inactiveBg = useColorModeValue('transparent', 'navy.700');
	let activeColor = useColorModeValue('gray.700', 'white');
	let inactiveColor = useColorModeValue('gray.400', 'gray.400');
	let activeAccordionBg = useColorModeValue('white', 'navy.700');
	let sidebarActiveShadow = useColorModeValue('0px 7px 11px rgba(0, 0, 0, 0.04)', 'none');
	let activeColorIcon = 'white';
	let inactiveColorIcon = 'blue.500';
	let sidebarBackgroundColor = useColorModeValue('white', 'navy.900');
    const icon = <HomeIcon />
    console.log(props.sidebarWidth)
    return (
        <Accordion key={key} allowToggle>
						<AccordionItem border='none'>
							<AccordionButton
								display='flex'
								align='center'
								justify='center'
								boxShadow={activeRoute('/adminsettings') && icon ? sidebarActiveShadow : null}
								_hover={{
									boxShadow: activeRoute('/adminsettings') && icon ? sidebarActiveShadow : null
								}}
								_focus={{
									boxShadow: 'none'
								}}
								borderRadius='8px'
								w={{
									sm: props.sidebarWidth === 275 ? '100%' : '77%',
									xl: props.sidebarWidth === 275 ? '90%' : '70%',
									'2xl': props.sidebarWidth === 275 ? '95%' : '77%'
								}}
								px={icon ? null : '0px'}
								py={icon ? '12px' : null}
								bg={activeRoute('/adminsettings') && prop.icon ? activeAccordionBg : 'transparent'}
								ms={props.sidebarWidth !== 275 ? !icon ? '12px' : '8px' : null}>
								{activeRoute('/adminsettings') ? (
									<Flex
										fontWeight='bold'
										boxSize='initial'
										justifyContent='flex-start'
										alignItems='center'
										bg='transparent'
										transition={variantChange}
										mx={{
											xl: 'auto'
										}}
										px='0px'
										borderRadius='8px'
										w='100%'
										_hover={{}}
										_active={{
											bg: 'inherit',
											transform: 'none',
											borderColor: 'transparent',
											border: 'none'
										}}
										_focus={{
											transform: 'none',
											borderColor: 'transparent',
											border: 'none'
										}}>
										{icon ? (
											<Flex justify={props.sidebarWidth === 275 ? 'flex-start' : 'center'}>
												<IconBox
													bg={activeBg}
													color={activeColorIcon}
													h='30px'
													w='30px'
													me={props.sidebarWidth === 275 ? '12px' : '0px'}
													transition={variantChange}>
													{prop.icon}
												</IconBox>
												<Text
													color={activeColor}
													my='auto'
													fontSize='sm'
													display={props.sidebarWidth === 275 ? 'block' : 'none'}>
													{prop.name}
												</Text>
											</Flex>
										) : (
											<HStack
												spacing={props.sidebarWidth === 275 ? '22px' : '0px'}
												ps={props.sidebarWidth === 275 ? '10px' : '0px'}
												ms={props.sidebarWidth === 275 ? '0px' : '8px'}>
												<Icon
													as={FaCircle}
													w='10px'
													color='blue.500'
													display={props.sidebarWidth === 275 ? 'block' : 'none'}
												/>
												<Text color={activeColor} my='auto' fontSize='sm'>
													{props.sidebarWidth === 275 ? prop.name : prop.name[0]}
												</Text>
											</HStack>
										)}
									</Flex>
								) : (
									<Flex
										fontWeight='bold'
										boxSize='initial'
										justifyContent='flex-start'
										alignItems='center'
										bg='transparent'
										mx={{
											xl: 'auto'
										}}
										px='0px'
										borderRadius='8px'
										w='100%'
										_hover={{}}
										_active={{
											bg: 'inherit',
											transform: 'none',
											borderColor: 'transparent'
										}}
										_focus={{
											borderColor: 'transparent',
											boxShadow: 'none'
										}}>
										{icon ? (
											<Flex justify={props.sidebarWidth === 275 ? 'flex-start' : 'center'}>
												<IconBox
													bg={inactiveBg}
													color={inactiveColorIcon}
													h='30px'
													w='30px'
													me={props.sidebarWidth === 275 ? '12px' : '0px'}
													transition={variantChange}>
													<HomeIcon />
												</IconBox>
												<Text
													color={inactiveColor}
													my='auto'
													fontSize='sm'
													display={props.sidebarWidth === 275 ? 'block' : 'none'}>
													Settings
												</Text>
											</Flex>
										) : (
											<HStack
												spacing={props.sidebarWidth === 275 ? '26px' : '0px'}
												ps={props.sidebarWidth === 275 ? '10px' : '0px'}
												ms={props.sidebarWidth === 275 ? '0px' : '8px'}>
												<Icon
													as={FaCircle}
													w='6px'
													color={'white'}
													display={props.sidebarWidth === 275 ? 'block' : 'none'}
												/>
												<Text color={inactiveColor} my='auto' fontSize='md' fontWeight='normal'>
													Settings
												</Text>
											</HStack>
										)}
									</Flex>
								)}
								<AccordionIcon
									color={'white'}
									display={
										icon ? props.sidebarWidth === 275 ? (
											'block'
										) : (
											'none'
										) : props.sidebarWidth === 275 ? (
											'block'
										) : (
											'none'
										)
									}
									transform={icon ? null : props.sidebarWidth === 275 ? null : 'translateX(-70%)'}
								/>
							</AccordionButton>
							<AccordionPanel
								pe={icon ? null : '0px'}
								pb='8px'
								ps={icon ? null : props.sidebarWidth === 275 ? null : '8px'}>
								<List>
                                    <NavLink key={key} to={'/admin/adminsettings/sms'}>
                                        <ListItem key={key} pt='5px' ms={props.sidebarWidth === 275 ? '26px' : '12px'}>
                                            <Text
                                            mb='4px'
                                            color={activeRoute('/adminsettings/sms') ? activeColor : inactiveColor}
                                            fontWeight={activeRoute('/adminsettings/sms') ? 'bold' : 'normal'}
                                            fontSize='sm'>
                                            SMS Settings
                                            </Text>
                                        </ListItem>
                                    </NavLink>
								</List>
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
    )
}