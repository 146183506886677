/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect, useReducer, useState, useRef } from "react";

import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Link,
  Spinner,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
  VStack,
  StackDivider,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  List,
  ListItem,
} from "@chakra-ui/react";
// Assets
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar5 from "assets/img/avatars/avatar5.png";
import avatar6 from "assets/img/avatars/avatar6.png";
import ImageArchitect1 from "assets/img/ImageArchitect1.png";
import ImageArchitect2 from "assets/img/ImageArchitect2.png";
import ImageArchitect3 from "assets/img/ImageArchitect3.png";
import {
  FaCube,
  FaFacebook,
  FaInstagram,
  FaPenFancy,
  FaPlus,
  FaTwitter,
} from "react-icons/fa";
import { UnlockIcon } from '@chakra-ui/icons'
import { IoCloudyNight, IoDocumentsSharp } from "react-icons/io5";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { HSeparator } from "components/Separator/Separator";
import ApiService from 'hooks/useApi';

const reducer = (state, action) => {
  if (action.type === "SWITCH_ACTIVE") {
    if (action.payload === "overview") {
      const newState = {
        overview: true,
        teams: false,
        projects: false,
      };
      return newState;
    } else if (action.payload === "teams") {
      const newState = {
        overview: false,
        teams: true,
        projects: false,
      };
      return newState;
    } else if (action.payload === "projects") {
      const newState = {
        overview: false,
        teams: false,
        projects: true,
      };
      return newState;
    }
  }
  return state;
};

function Overview(props) {
  console.log(props)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [state, dispatch] = useReducer(reducer, {
    overview: true,
    teams: false,
    projects: false,
  });
  const [passwordResetIsLoading, setPasswordResetIsLoading] = useState(false)

  const { colorMode } = useColorMode();

  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const iconColor = useColorModeValue("blue.500", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");

  const [name, setName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [emailAddress, setemailAddress] = useState("")
  const [IsLoaded, setIsLoaded] = useState(false)
  const [appCategories, setAppCategories] = useState([])
  const [appPermissions, setAppPermissions] = useState([])
  const [toastSettings, setToast] = useState([])
  const toast = useToast()
  const toastIdRef = useRef()


  const userId = props.location.pathname.split('/').at(-1)

  function checkCategoryPermission(category_id){
    for(const i in appPermissions){
      if(appPermissions[i].category_id == category_id){
        if(appPermissions[i].status == 1){
          return true
        }
        else{
          return false
        }
      }
    }
    return false 
  }

  function handlePasswordReset(e){
    e.preventDefault()
    onClose()
    setPasswordResetIsLoading(true)
    ApiService.adminResetAccountPassword(userId).then(response => {
      toastIdRef.current = toast({
        title: 'Password Reset Email Sent',
        description: "Reset email for "+emailAddress+" sent successfully",
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      setPasswordResetIsLoading(false)
    })
  }

  function handlePermissionChange(e){
    e.preventDefault()
    console.log(e.target.checked)
    ApiService.setUserPermission(e.target.id, userId, e.target.checked).then(response => {
      toastIdRef.current = toast({
        title: 'Apps Permissons Updated',
        description: "User Permission Updated Successfully",
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    }).catch(response => {
      console.log(response)
    })
  }

  useEffect(() => {
    ApiService.getUserProfileAdmin(userId).then(data => {
      setName(data.first_name + " " + data.last_name)
      setFirstName(data.first_name)
      setemailAddress(data.email_address)
    })
    ApiService.getAppsCategories().then(data => {
      setAppCategories(data)
    }).then(() => {
      ApiService.getAppPermissions(userId).then(data => {
        const appPermissions = {}
        for(const i in data){
          const category_id = data[i].category_id
          appPermissions[category_id] = data[i].status
        }
        setAppPermissions(appPermissions)
        console.log(appPermissions)
        setIsLoaded(true)
      })
    })
    
  }, [])

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px", lg: "100px" }}>
      <Flex
        direction={{ sm: "column", md: "row" }}
        mb="24px"
        maxH="330px"
        justifyContent={{ sm: "center", md: "space-between" }}
        align="center"
        backdropFilter="blur(21px)"
        boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
        border="1.5px solid"
        borderColor={borderProfileColor}
        bg={bgProfile}
        p="24px"
        borderRadius="20px"
      >
        <Flex
          align="center"
          mb={{ sm: "10px", md: "0px" }}
          direction={{ sm: "column", md: "row" }}
          w={{ sm: "100%" }}
          textAlign={{ sm: "center", md: "start" }}
        >
          <Avatar
            me={{ md: "22px" }}
            src={avatar5}
            w="80px"
            h="80px"
            borderRadius="15px"
          />
          <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
            <Text
              fontSize={{ sm: "lg", lg: "xl" }}
              color={textColor}
              fontWeight="bold"
              ms={{ sm: "8px", md: "0px" }}
            >
              {name}
            </Text>
            <Text fontSize={{ sm: "sm", md: "md" }} color="gray.400">
              {emailAddress}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction={{ sm: "column", lg: "row" }}
          w={{ sm: "100%", md: "50%", lg: "auto" }}
        >
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex
              align="center"
              w={{ sm: "100%", lg: "135px" }}
              bg={
                state.overview
                  ? colorMode === "dark"
                    ? "navy.900"
                    : "#fff"
                  : null
              }
              borderRadius="8px"
              justifyContent="center"
              py="10px"
              boxShadow={
                state.overview ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null
              }
              cursor="pointer"
              transition="all .5s ease"
              onClick={() =>
                dispatch({ type: "SWITCH_ACTIVE", payload: "overview" })
              }
            >
              <Icon color={textColor} as={FaCube} me="6px" />
              <Text fontSize="xs" color={textColor} fontWeight="bold">
                OVERVIEW
              </Text>
            </Flex>
          </Button>
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex
              align="center"
              w={{ lg: "135px" }}
              borderRadius="8px"
              justifyContent="center"
              py="10px"
              mx={{ lg: "1rem" }}
              cursor="pointer"
              boxShadow={
                state.teams ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null
              }
              bg={
                state.teams
                  ? colorMode === "dark"
                    ? "navy.900"
                    : "#fff"
                  : null
              }
              transition="all .5s ease"
              onClick={() =>
                dispatch({ type: "SWITCH_ACTIVE", payload: "teams" })
              }
            >
              <Icon color={textColor} as={IoDocumentsSharp} me="6px" />
              <Text fontSize="xs" color={textColor} fontWeight="bold">
                BRANCHES
              </Text>
            </Flex>
          </Button>
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex
              align="center"
              w={{ lg: "135px" }}
              borderRadius="8px"
              justifyContent="center"
              py="10px"
              cursor="pointer"
              boxShadow={
                state.projects ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null
              }
              bg={
                state.projects
                  ? colorMode === "dark"
                    ? "navy.900"
                    : "#fff"
                  : null
              }
              transition="all .5s ease"
              onClick={() =>
                dispatch({ type: "SWITCH_ACTIVE", payload: "projects" })
              }
            >
              <Icon color={textColor} as={FaPenFancy} me="6px" />
              <Text fontSize="xs" color={textColor} fontWeight="bold">
                PROPERTIES
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>

      <Grid templateColumns={{ sm: "1fr", xl: "repeat(3, 1fr)" }} gap="22px">
        <Card p="16px">
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Portal Settings
            </Text>
          </CardHeader>
          {IsLoaded ? (
            <CardBody px="5px">
            <Flex direction="column">
              <Text fontSize="sm" color="gray.400" fontWeight="600" mb="20px">
                Apps Categories
              </Text>
              {appCategories.map((category) => (
                    <Flex align="center" mb="20px">
                      {appPermissions[category.id] == 1 ? (
                        <Switch colorScheme="blue" me="10px" defaultChecked onChange={handlePermissionChange} id={category.id}/>
                      ): <Switch colorScheme="blue" me="10px" onChange={handlePermissionChange} id={category.id}/>}
                    
                    <Text
                      noOfLines={1}
                      fontSize="md"
                      color="gray.400"
                      fontWeight="400"
                    >
                      {category.category_name}
                    </Text>
                  </Flex>
                  ))}
             
              
            </Flex>
          </CardBody>
          ): <Spinner></Spinner>}
        </Card>
        <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Profile information
            </Text>
          </CardHeader>
          <CardBody px="5px">
            <Flex direction="column">
              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  Full Name:{" "}
                </Text>
                <Text fontSize="md" color="gray.400" fontWeight="400">
                  Esthera Jackson
                </Text>
              </Flex>
              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  Mobile:{" "}
                </Text>
                <Text fontSize="md" color="gray.400" fontWeight="400">
                  (44) 123 1234 123
                </Text>
              </Flex>
              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  Email:{" "}
                </Text>
                <Text fontSize="md" color="gray.400" fontWeight="400">
                  esthera@simmmple.com
                </Text>
              </Flex>
              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  Location:{" "}
                </Text>
                <Text fontSize="md" color="gray.400" fontWeight="400">
                  United States
                </Text>
              </Flex>
              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  Social Media:{" "}
                </Text>
                <Flex>
                  <Link
                    href="#"
                    color={iconColor}
                    fontSize="lg"
                    me="10px"
                    _hover={{ color: "blue.500" }}
                  >
                    <Icon as={FaFacebook} />
                  </Link>
                  <Link
                    href="#"
                    color={iconColor}
                    fontSize="lg"
                    me="10px"
                    _hover={{ color: "blue.500" }}
                  >
                    <Icon as={FaInstagram} />
                  </Link>
                  <Link
                    href="#"
                    color={iconColor}
                    fontSize="lg"
                    me="10px"
                    _hover={{ color: "blue.500" }}
                  >
                    <Icon as={FaTwitter} />
                  </Link>
                </Flex>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
        <Card p="16px">
        <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reset Account Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text>
              The following user account will receive an email to reset their password:
              <List>
                <ListItem>{emailAddress}</ListItem>
              </List>
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={handlePasswordReset}>
              Reset
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Account Tasks
            </Text>
          </CardHeader>
          <CardBody px="5px">
            <Flex direction="column" w="100%">
              <Flex justifyContent="space-between" mb="21px">
                <Flex align="center">
                <Stack direction='column' spacing={4}>
                  {passwordResetIsLoading ? (
                    <Button leftIcon={<UnlockIcon />} onClick={onOpen} colorScheme='blue' variant='solid' width="100%" isLoading loadingText="Resetting">
                    Reset Password
                  </Button>
                  ): <Button leftIcon={<UnlockIcon />} onClick={onOpen} colorScheme='blue' variant='solid' width="100%" loadingText="Resetting">
                  Reset Password
                </Button>}
                  
                  <Button leftIcon={<UnlockIcon />} colorScheme='red' variant='solid'>
                    Delete Account
                  </Button>
                </Stack>
                </Flex>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Grid>
      <Card p="16px" my="24px">
        <CardHeader p="12px 5px" mb="12px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Branches
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="400">
              {firstName}'s Branches
            </Text>
          </Flex>
        </CardHeader>
        <CardBody px="5px">
          <Grid
            templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
            templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
            gap="24px"
          >
            <Flex direction="column">
              <Box mb="20px" position="relative" borderRadius="15px">
                <Image src={ImageArchitect1} borderRadius="15px" />
                <Box
                  w="100%"
                  h="100%"
                  position="absolute"
                  top="0"
                  borderRadius="15px"
                  bg="linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, rgba(21, 25, 40, 0.88) 100%)"
                ></Box>
              </Box>
              <Flex direction="column">
                <Text fontSize="md" color="gray.400" fontWeight="600" mb="10px">
                  Project #1
                </Text>
                <Text
                  fontSize="xl"
                  color={textColor}
                  fontWeight="bold"
                  mb="10px"
                >
                  Modern
                </Text>
                <Text fontSize="md" color="gray.400" fontWeight="400" mb="20px">
                  As Uber works through a huge amount of internal management
                  turmoil.
                </Text>
                <Flex justifyContent="space-between">
                  <Button variant="dark" minW="110px" h="36px">
                    VIEW ALL
                  </Button>
                  <AvatarGroup size="xs">
                    <Avatar name="Ryan Florence" src={avatar6} />
                    <Avatar name="Segun Adebayo" src={avatar2} />
                    <Avatar name="Kent Dodds" src={avatar3} />
                    <Avatar name="Prosper Otemuyiwa" src={avatar4} />
                  </AvatarGroup>
                </Flex>
              </Flex>
            </Flex>
            <Flex direction="column">
              <Box mb="20px" position="relative" borderRadius="15px">
                <Image src={ImageArchitect2} borderRadius="15px" />
                <Box
                  w="100%"
                  h="100%"
                  position="absolute"
                  top="0"
                  borderRadius="15px"
                  bg="linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, rgba(21, 25, 40, 0.88) 100%)"
                ></Box>
              </Box>
              <Flex direction="column">
                <Text fontSize="md" color="gray.400" fontWeight="600" mb="10px">
                  Project #2
                </Text>
                <Text
                  fontSize="xl"
                  color={textColor}
                  fontWeight="bold"
                  mb="10px"
                >
                  Scandinavian
                </Text>
                <Text fontSize="md" color="gray.400" fontWeight="400" mb="20px">
                  Music is something that every person has his or her own
                  specific opinion about.
                </Text>
                <Flex justifyContent="space-between">
                  <Button variant="dark" minW="110px" h="36px">
                    VIEW ALL
                  </Button>
                  <AvatarGroup size="xs">
                    <Avatar name="Ryan Florence" src={avatar6} />
                    <Avatar name="Segun Adebayo" src={avatar2} />
                    <Avatar name="Kent Dodds" src={avatar3} />
                    <Avatar name="Prosper Otemuyiwa" src={avatar4} />
                  </AvatarGroup>
                </Flex>
              </Flex>
            </Flex>
            <Flex direction="column">
              <Box mb="20px" position="relative" borderRadius="15px">
                <Image src={ImageArchitect3} borderRadius="15px" />
                <Box
                  w="100%"
                  h="100%"
                  position="absolute"
                  top="0"
                  borderRadius="15px"
                  bg="linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, rgba(21, 25, 40, 0.88) 100%)"
                ></Box>
              </Box>
              <Flex direction="column">
                <Text fontSize="md" color="gray.400" fontWeight="600" mb="10px">
                  Project #3
                </Text>
                <Text
                  fontSize="xl"
                  color={textColor}
                  fontWeight="bold"
                  mb="10px"
                >
                  Minimalist
                </Text>
                <Text fontSize="md" color="gray.400" fontWeight="400" mb="20px">
                  Different people have different taste, especially various
                  types of music.
                </Text>
                <Flex justifyContent="space-between">
                  <Button variant="dark" minW="110px" h="36px">
                    VIEW ALL
                  </Button>
                  <AvatarGroup size="xs">
                    <Avatar name="Ryan Florence" src={avatar6} />
                    <Avatar name="Segun Adebayo" src={avatar2} />
                    <Avatar name="Kent Dodds" src={avatar3} />
                    <Avatar name="Prosper Otemuyiwa" src={avatar4} />
                  </AvatarGroup>
                </Flex>
              </Flex>
            </Flex>
            <Button
              p="0px"
              bg="transparent"
              border="1px solid lightgray"
              borderRadius="15px"
              minHeight={{ sm: "200px", md: "100%" }}
            >
              <Flex direction="column" justifyContent="center" align="center">
                <Icon as={FaPlus} color={textColor} fontSize="lg" mb="12px" />
                <Text fontSize="lg" color={textColor} fontWeight="bold">
                  Create a new Branch
                </Text>
              </Flex>
            </Button>
          </Grid>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Overview;
