import { useDisclosure } from "@chakra-ui/react"
import { 
    Button, 
    Modal, 
    ModalOverlay, 
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Text,
    useColorModeValue,
    FormControl,
    FormLabel, 
    Input,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Stack,
    useToast,
} from "@chakra-ui/react"
import { useState } from "react"
import ApiService from 'hooks/useApi'

export default function BasicUsage(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [inviteButton, setInviteButton] = useState(false)
    const [emailAddress, setEmailAddress] = useState("")
    const [errMessage, setErrMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const toast = useToast()


    function handleEmailChange(e){
        setEmailAddress(e.target.value)
      }

    function handleSubmit(e){
        e.preventDefault()
        setInviteButton(true)
        props.disableState(false)
        var feed_enabled = props.currentState
        if (props.currentState == true){
          feed_enabled = false
        }else{
          feed_enabled = true
        }
        console.log(props.feed_url)
        ApiService.updateBranchFeed(props.branch_id, props.feed_id, feed_enabled, props.feed_url).then(data => {
          toast({
            title: props.feedType + ' feed updated.',
            description: "Feed has been updated",
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
          props.callback()
          setInviteButton(false)
        }).catch(err => {
          toast({
            title: 'Could not update feed',
            description: "An error occurred and the feed could not be updated" + err,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
    }
    function handleClose(e){
      props.disableState(false)
    }
    return (
      <>
        <Modal isOpen={props.isOpen} onClose={handleClose}>
          <ModalOverlay />
          <ModalContent>
            {props.currentState ? (<ModalHeader>Disable Feed</ModalHeader>):(<ModalHeader>Enable Feed</ModalHeader>)}
            <ModalCloseButton />
            <ModalBody>
            {props.currentState ? (<Text>{props.feedType} feed will be disabled. This feed will no-longer be automatically updated</Text>):(<Text>{props.feedType} will be enabled. This feed will now be automatically updated.</Text>)}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='red' mr={3} onClick={handleClose}>
                Close
              </Button>
              {props.currentState ? (<Button 
              colorScheme='blue'
              disabled={inviteButton}
              onClick={handleSubmit}
              >Disable</Button>):(
                <Button 
              colorScheme='blue'
              disabled={inviteButton}
              onClick={handleSubmit}
              >Enable</Button>
              )}
              
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }